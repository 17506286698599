import { useParams } from 'react-router-dom'
import EditInstrumentForm from './EditInstrumentForm'
import { useGetInstrumentsQuery } from './instrumentsApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditInstrument = () => {
    useTitle('Edit Instrument')

    const { id } = useParams()

    const { instrument } = useGetInstrumentsQuery("instrumentsList", {
        selectFromResult: ({ data }) => ({
            instrument: data?.entities[id]
        }),
    })

    if (!instrument) return <PulseLoader color={"#FFF"} />

    const content = <EditInstrumentForm instrument={instrument} />

    return content
}
export default EditInstrument