import React, { useState, useEffect } from "react";
import { useGetAllApprovedScoresQuery } from "../features/scores/scoresApiSlice";
import { Input, IconButton, List, ListItem, ListItemText } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const ScoreSearchBar = ({ onSearch }) => {
  const navigate = useNavigate();
  const {
    data: scores,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllApprovedScoresQuery("scoresList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [scoreResults, setScoreResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTriggered, setSearchTriggered] = useState(false);

  useEffect(() => {
    if (searchQuery && isSuccess) {
      const scoreTitles = Object.values(scores.entities).map(
        (score) => score.title
      );
      const results = scoreTitles.filter((score) =>
        score.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setScoreResults(results.slice(0, 5)); // Limit to first 5 results
      setShowSuggestions(results.length > 0);
    } else {
      setScoreResults([]);
      setShowSuggestions(false);
    }
  }, [searchQuery, isSuccess, scores]);

  const handleSearchClick = () => {
    setSearchTriggered(true);
    const results = Object.values(scores.entities)
      .filter((score) =>
        score.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map((score) => score.id);
    onSearch(results, searchQuery);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (scoreId) => {
    navigate(`/dash/scores/${scoreId}`);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          id="scoreSearch"
          placeholder="Type a score title..."
          value={searchQuery}
          style={{ width: '100%' }}
          onChange={(e) => {
            const query = e.target.value;
            setSearchQuery(query);
            if (query === '') {
              onSearch([], query); // Clear suggestions when search query is empty
              setScoreResults([]);
              setShowSuggestions(false);
              setSearchTriggered(false);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchClick();
            }
          }}
        />
        <IconButton onClick={handleSearchClick}>
          <SearchIcon />
        </IconButton>
      </div>
      {showSuggestions && (
        <List>
          {scoreResults.map((score, index) => {
            const scoreEntity = Object.values(scores.entities).find(
              (entity) => entity.title === score
            );
            return (
              <ListItem
                key={index}
                button
                onClick={() => handleSuggestionClick(scoreEntity.id)}
              >
                <ListItemText primary={score} />
              </ListItem>
            );
          })}
        </List>
      )}
      {searchTriggered && searchQuery && scoreResults.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Sorry, no results for "{searchQuery}", try searching a different score.
        </div>
      )}
      {searchTriggered && searchQuery && scoreResults.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Results for "{searchQuery}"
        </div>
      )}
    </>
  );
};

export default ScoreSearchBar;