import { useState, useEffect } from "react";
import { useAddNewUserMutation } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";
import { ROLES } from "../../config/roles";
import useTitle from "../../hooks/useTitle";

const USER_REGEX = /^[A-z]{3,20}$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/;

const NewUserForm = () => {
  useTitle("New User");

  const [addNewUser, { isLoading, isSuccess, isError, error }] = useAddNewUserMutation();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [roles, setRoles] = useState(["Visitor"]);

  useEffect(() => {
    setValidUsername(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidConfirmPassword(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (isSuccess) {
      setUsername("");
      setPassword("");
      setConfirmPassword("");
      setRoles([]);
      navigate("/dash/users");
    }
  }, [isSuccess, navigate]);

  const onUsernameChanged = (e) => setUsername(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);
  const onConfirmPasswordChanged = (e) => setConfirmPassword(e.target.value);

  const onRolesChanged = (e) => {
    const { value } = e.target;
    setRoles(typeof value === 'string' ? value.split(',') : value);
  };

  const canSave = [roles.length, validUsername, validPassword, validConfirmPassword].every(Boolean) && !isLoading;

  const onSaveUserClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewUser({ username, password, roles });
    }
  };

  const options = Object.values(ROLES).map((role) => (
    <MenuItem key={role} value={role}>
      {role}
    </MenuItem>
  ));

  const errClass = isError ? "errmsg" : "offscreen";
  const validUserClass = !validUsername ? "form__input--incomplete" : "";
  const validPwdClass = !validPassword ? "form__input--incomplete" : "";
  const validConfirmPwdClass = !validConfirmPassword ? "form__input--incomplete" : "";
  const validRolesClass = !Boolean(roles.length) ? "form__input--incomplete" : "";

  const errContent = error?.data?.message ?? "";

  let content;

  if (isLoading) {
    content = (
      <section className="dash-container">
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  } else {
    content = (
      <section className="dash-container">
        <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
          <Typography variant="h3" align="center" mb={2}>
            Add New User
          </Typography>

          <form onSubmit={onSaveUserClicked}>
            <TextField
              fullWidth
              label="Username"
              value={username}
              onChange={onUsernameChanged}
              error={!validUsername}
              helperText={!validUsername ? "Username must be 3-20 letters" : ""}
              margin="normal"
            />

            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={onPasswordChanged}
              error={!validPassword}
              helperText={!validPassword ? "Password must be 4-12 chars incl. !@#$%" : ""}
              margin="normal"
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={onConfirmPasswordChanged}
              error={!validConfirmPassword}
              helperText={!validConfirmPassword ? "Passwords must match" : ""}
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="roles-label" style={{ backgroundColor: "white" }}>
                Assigned Roles
              </InputLabel>
              <Select
                labelId="roles-label"
                id="roles"
                multiple
                value={roles}
                onChange={onRolesChanged}
                renderValue={(selected) => selected.join(", ")}
                error={!Boolean(roles.length)}
              >
                {options}
              </Select>
            </FormControl>

            <Button
              type="submit"
              disabled={!canSave}
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Save User
            </Button>
          </form>
          {isError && <p className="errmsg">{errContent}</p>}
        </Paper>
      </section>
    );
  }

  return content;
};

export default NewUserForm;