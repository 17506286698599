import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetInstrumentQuery } from './instrumentsApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  Paper,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const InstrumentMain = () => {
  const { id } = useParams();

  const {
    data: instrument,
    isLoading: isInstrumentsLoading,
    isSuccess: isInstrumentSuccess,
    isError: isInstrumentError,
    error: instrumentError,
  } = useGetInstrumentQuery(id);

  if (isInstrumentsLoading) {
    return (
      <section className="dash-container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  }

  if (isInstrumentError) {
    return <p className="errmsg">{instrumentError?.data?.message}</p>;
  }

  if (isInstrumentSuccess) {
    return (
      <section className="dash-container">
        <Paper
          style={{ padding: "20px", margin: "20px auto", maxWidth: "800px",  textAlign: "center" }}
        >
          <Typography variant="h3" align="center">
            {instrument.name}
          </Typography>
          <img
            src={`${instrument.selectedFile}`}
            alt={instrument.name}
            style={{ width: "30%", height: "auto", marginTop: "1em", borderRadius: "10%" }}
          />
          <Grid container spacing={2} style={{ marginTop: "1em", marginBottom: "1em" }}>
            <Grid item xs={12}>
              <Typography variant="h6">Tuning</Typography>
              <Typography variant="body1">{instrument.tuning}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                component={Link}
                to="/dash/instruments"
                startIcon={<ArrowBackIcon />}
              >
                Back to Instruments List
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </section>
    );
  }

  return null;
};

export default InstrumentMain;