import { useParams } from 'react-router-dom'
import EditComposerForm from './EditComposerForm'
import { useGetComposerQuery } from './composersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditComposer = () => {
    useTitle('Edit Composer')

    const { id } = useParams()

    const {
        data: composer,
        isLoading: isComposerLoading,
        isSuccess: isComposerSuccess,
        isError: isComposerError,
        error: composerError,
      } = useGetComposerQuery(id);

    if (!composer) return <PulseLoader color={"#FFF"} />

    const content = <EditComposerForm composer={composer} />

    return content
}
export default EditComposer