import React, { useState, useEffect } from "react";
import { useGetComposersQuery } from "../features/composers/composersApiSlice";
import { Input, IconButton, List, ListItem, ListItemText } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const ComposerSearchBar = ({ onSearch }) => {
  const navigate = useNavigate();
  const {
    data: composers,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetComposersQuery("composersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [composerResults, setComposerResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTriggered, setSearchTriggered] = useState(false);

  useEffect(() => {
    if (searchQuery && isSuccess) {
      const composerNames = Object.values(composers.entities).map(
        (composer) => composer.name
      );
      const results = composerNames.filter((composer) =>
        composer.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setComposerResults(results.slice(0, 5)); // Limit to first 5 results
      setShowSuggestions(results.length > 0);
    } else {
      setComposerResults([]);
      setShowSuggestions(false);
    }
  }, [searchQuery, isSuccess, composers]);

  const handleSearchClick = () => {
    setSearchTriggered(true);
    const results = Object.values(composers.entities)
      .filter((composer) =>
        composer.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map((composer) => composer.id);
    onSearch(results, searchQuery);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (composerId) => {
    navigate(`/dash/composers/${composerId}`);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          id="composerSearch"
          placeholder="Type a composer name..."
          value={searchQuery}
          style={{ width: '100%' }}
          onChange={(e) => {
            const query = e.target.value;
            setSearchQuery(query);
            if (query === '') {
              onSearch([], query); // Clear suggestions when search query is empty
              setComposerResults([]);
              setShowSuggestions(false);
              setSearchTriggered(false);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchClick();
            }
          }}
        />
        <IconButton onClick={handleSearchClick}>
          <SearchIcon />
        </IconButton>
      </div>
      {showSuggestions && (
        <List>
          {composerResults.map((composer, index) => {
            const composerEntity = Object.values(composers.entities).find(
              (entity) => entity.name === composer
            );
            return (
              <ListItem
                key={index}
                button
                onClick={() => handleSuggestionClick(composerEntity.id)}
              >
                <ListItemText primary={composer} />
              </ListItem>
            );
          })}
        </List>
      )}
      {searchTriggered && searchQuery && composerResults.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Sorry, no results for "{searchQuery}", try searching a different composer.
        </div>
      )}
      {searchTriggered && searchQuery && composerResults.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Results for "{searchQuery}"
        </div>
      )}
    </>
  );
};

export default ComposerSearchBar;