import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const composersAdapter = createEntityAdapter({})

const initialState = composersAdapter.getInitialState()

export const composersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getComposers: builder.query({
            query: () => ({
                url: '/composers',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedComposers = responseData.map(composer => {
                    composer.id = composer._id
                    return composer
                });
                return composersAdapter.setAll(initialState, loadedComposers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Composer', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Composer', id }))
                    ]
                } else return [{ type: 'Composer', id: 'LIST' }]
            }
        }),
        getComposer: builder.query({
            query: id => ({
                url: `/composers/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const composer = responseData
                composer.id = composer._id
                return composer
            },
            providesTags: (result, error, arg) => [{ type: 'Composer', id: arg }]
        }),
        addNewComposer: builder.mutation({
            query: initialComposerData => ({
                url: '/composers',
                method: 'POST',
                body: {
                    ...initialComposerData,
                }
            }),
            invalidatesTags: [
                { type: 'Composer', id: "LIST" }
            ]
        }),
        updateComposer: builder.mutation({
            query: initialComposerData => ({
                url: '/composers',
                method: 'PATCH',
                body: {
                    ...initialComposerData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Composer', id: arg.id }
            ]
        }),
        deleteComposer: builder.mutation({
            query: ({ id }) => ({
                url: `/composers`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Composer', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetComposersQuery,
    useGetComposerQuery,
    useAddNewComposerMutation,
    useUpdateComposerMutation,
    useDeleteComposerMutation,
} = composersApiSlice

// returns the query result object
export const selectComposersResult = composersApiSlice.endpoints.getComposers.select()

// creates memoized selector
const selectComposersData = createSelector(
    selectComposersResult,
    composersResult => composersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllComposers,
    selectById: selectComposerById,
    selectIds: selectComposerIds
    // Pass in a selector that returns the composers slice of state
} = composersAdapter.getSelectors(state => selectComposersData(state) ?? initialState)