import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";
import useAuth from "../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const DASH_REGEX = /^\/dash(\/)?$/;
const SCORES_REGEX = /^\/dash\/scores(\/)?$/;
const USERS_REGEX = /^\/dash\/users(\/)?$/;

const DashHeader = () => {
  const { username, isAdmin, isEmployee, isAssistant, isVisitor } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [sendLogout, { isLoading, isSuccess, isError, error }] = useSendLogoutMutation();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  let dashClass = null;
  if (!DASH_REGEX.test(pathname) && !SCORES_REGEX.test(pathname) && !USERS_REGEX.test(pathname)) {
    dashClass = "dash-header__container--small";
  }

  const logoutButton = (
    <Button
      variant="contained"
      title="Logout"
      color="secondary"
      onClick={sendLogout}
      startIcon={<LogoutIcon />}
    >
      Logout
    </Button>
  );

  const errClass = isError ? "errmsg" : "offscreen";

  let buttonContent;
  if (isLoading) {
    buttonContent = <PulseLoader color={"black"} />;
  } else {
    buttonContent = <>{logoutButton}</>;
  }

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <div>
      <List>
        <ListItem  component={Link} to="/dash">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem  component={Link} to="/dash/scores/new">
          <ListItemText primary="Contribute" />
        </ListItem>
        <ListItem  component={Link} to="/dash/scores">
          <ListItemText primary="Scores" />
        </ListItem>
        <ListItem  component={Link} to="/dash/composers">
          <ListItemText primary="Composers" />
        </ListItem>
        <ListItem  component={Link} to="/dash/instruments">
          <ListItemText primary="Instruments" />
        </ListItem>
        <ListItem  component={Link} to="/dash/analytics">
          <ListItemText primary="Analytics" />
        </ListItem>
        {(isEmployee || isAdmin) && (
          <ListItem  component={Link} to="/dash/users">
            <ListItemText primary="Users" />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem>{buttonContent}</ListItem>
        {/* my account button */}
        <ListItem  component={Link} to="/dash/account">
          <Button
            variant="contained"
            title="Account"
            style={{ color: 'white' }}
            startIcon={<AccountCircleIcon />}
          >
            {username}
          </Button>
        </ListItem>
      </List>
    </div>
  );

  // center logo for small screens
  let justify_content
  if (isSmall) {
    justify_content = "center";
  } else {
    justify_content = "space-between";
  }

  const content = (
    <>
      <p className={errClass} style={{ margin: 0 }}>{error?.data?.message}</p>
      <AppBar position="sticky">
        <Toolbar style={{ justifyContent: justify_content }}>
          {isSmall && (
            <IconButton  edge="start" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          )}
          <IconButton>
              <img src="/multiscoreLogo.jpg" alt="Logo" width="40" />
              <Typography
                variant="h6"
                noWrap
                component={Link}
                to="/dash"
                style={{ textDecoration: "none", color: 'black', fontWeight: "bold", marginLeft: '1rem' }}
              >
              MultiScore
            </Typography>
          </IconButton>
            {!isSmall && (
            <>
              <Typography
                variant="p"
                noWrap
                component={Link}
                fontWeight="bold"
                to="/dash/scores/new"
                style={{ textDecoration: "none", color: pathname === "/dash/scores/new" ? '#82a6a1' : 'black', margin: '0.5rem' }}
              >
                CONTRIBUTE
              </Typography>
              {(isEmployee || isAdmin) && (
                <Typography
                  variant="p"
                  noWrap
                  component={Link}
                  to="/dash/scores/submissions"
                  style={{ textDecoration: "none", color: (pathname.startsWith("/dash/scores/submissions") ) ? '#82a6a1' : 'black', margin: '0.5rem' }}
                >
                  SUBMISSIONS
                </Typography>
              )}
              <Typography
                variant="p"
                noWrap
                component={Link}
                to="/dash/scores"
                style={{ textDecoration: "none", color: (pathname.startsWith("/dash/scores") && pathname !== "/dash/scores/new" && !pathname.startsWith("/dash/scores/submissions")) ? '#82a6a1' : 'black', margin: '0.5rem' }}
              >
                SCORES
              </Typography>
              <Typography
                variant="p"
                noWrap
                component={Link}
                to="/dash/composers"
                style={{ textDecoration: "none", color: (pathname.startsWith('/dash/composers')) ? '#82a6a1' : 'black', margin: '0.5rem' }}
              >
                COMPOSERS
              </Typography>
              <Typography
                variant="p"
                noWrap
                component={Link}
                to="/dash/instruments"
                style={{ textDecoration: "none", color: (pathname.startsWith('/dash/instruments')) ? '#82a6a1' : 'black', margin: '0.5rem' }}
              >
                INSTRUMENTS
              </Typography>
              <Typography
                variant="p"
                noWrap
                component={Link}
                to="/dash/analytics"
                style={{ textDecoration: "none", color: pathname === "/dash/analytics" ? '#82a6a1' : 'black', margin: '0.5rem' }}
              >
                ANALYTICS
              </Typography>
              {(isEmployee || isAdmin) && (
                <Typography
                  variant="p"
                  noWrap
                  component={Link}
                  to="/dash/users"
                  style={{ textDecoration: "none", color: pathname === "/dash/users" ? '#82a6a1' : 'black', margin: '0.5rem' }}
                >
                  USERS
                </Typography>
              )}
              <div>
                {buttonContent}
              </div>
              {/* Account */}
              <IconButton>
                <Button
                  variant="contained"
                  title="Account"
                  style={{ color: 'white' }}
                  component={Link}
                  to="/dash/account"
                  startIcon={<AccountCircleIcon />}
                >
                  {username}
                </Button>
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        {drawer}
      </Drawer>
    </>
  );

  return content;
};

export default DashHeader;