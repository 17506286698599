import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import LoginIcon from "@mui/icons-material/Login";


const PublicHeader = ({ onLogin }) => {
  return (
    <AppBar position="sticky" style={{ margin: 0 }}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        {/* <CameraIcon sx={{ mr: 2 }} /> */}
        <IconButton edge="start" aria-label="logo" >
          <img src="/multiscoreLogo.jpg" alt="Logo" width="40" />
          <Typography
          variant="h6"
          noWrap
          component={Link}
          to="/"
          style={{ textDecoration: "none",color:'black', fontWeight: "bold", marginLeft: '1rem'}}
          >
            MultiScore
          </Typography>
        </IconButton>
        
        {onLogin ? null : (
          <Button
            variant="contained"
            color="secondary"
            to="/login"
            style={{ color: 'white' }}
            component={Link}
            startIcon={<LoginIcon />}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default PublicHeader;
