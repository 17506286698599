import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CONTENT_LICENSES } from "../../config/licenses";
import useTitle from "../../hooks/useTitle";
import { useAddNewScoreMutation } from "./scoresApiSlice";
import { useGetComposersQuery } from "../composers/composersApiSlice";
import { useGetInstrumentsQuery } from "../instruments/instrumentsApiSlice";
import AddIcon from "@mui/icons-material/Add";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Box,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { useSelector } from 'react-redux';


const NewScoreForm = () => {
  useTitle("Contribute to MUSCAT");

  const navigate = useNavigate();

  const [addNewScore, { isLoading, isSuccess, isError, error }] = useAddNewScoreMutation();
  const { data: composersData, isLoading: isComposersLoading, isSuccess: isComposersSuccess } = useGetComposersQuery();
  const { data: instrumentsData, isLoading: isInstrumentsLoading, isSuccess: isInstrumentsSuccess } = useGetInstrumentsQuery();

  const [title, setTitle] = useState("");
  const [scoreLicense, setScoreLicense] = useState("");
  const [scoreUrl, setScoreUrl] = useState("");
  const [composer, setComposer] = useState("");
  const [newComposer, setNewComposer] = useState("");
  const [newComposerBio, setNewComposerBio] = useState("");
  const [isAddingNewComposer, setIsAddingNewComposer] = useState(false);
  const [prints, setPrints] = useState([]);
  const [audios, setAudios] = useState([]);
  const [scoreFiles, setScoreFiles] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [open, setOpenSuccess] = useState(false);
  const [openMissingFieldsDialog, setOpenMissingFieldsDialog] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isResponseLoading, setIsResponseLoading] = useState(false);

  const { username } = useAuth();

  const token = useSelector((state) => state.auth.token); // get token from redux store


  const canSave = () => {
    const missing = [];

    // Check main fields
    if (!title) missing.push('Title');
    if (!(composer || newComposer)) missing.push('Composer');
    if (!scoreLicense) missing.push('Score License');
    if (!scoreUrl) missing.push('Score URL');

    // Check score files
    if (scoreFiles.length === 0 || scoreFiles.some(file => !file.file)) {
      missing.push('Score Files');
    }

    // Check prints
    if (prints.length === 0 || prints.some(print => !print.license || prints.some(print => !print.url) || !print.file)) {
      missing.push('Prints');
    }

    // Check audios
    if (audios.length === 0 || audios.some(audio => {
      if (!audio.license || !audio.file || !audio.performer || !audio.url) {
        return true;
      }
      // Check instruments within each audio
      return audio.instruments.some(instrument => {
        if (instrument.isNew) {
          return !instrument.name || !instrument.tuning;
        }
        return !instrument.id;
      });
    })) {
      missing.push('Audios');
    }

    return missing;
  };

  const handleScoreFileAdd = () => {
    setScoreFiles([...scoreFiles, { file: null, edited: false }]);
  };

  const handleScoreFileRemove = (index) => {
    const updatedScoreFiles = [...scoreFiles];
    updatedScoreFiles.splice(index, 1);
    setScoreFiles(updatedScoreFiles);
  };

  // Add an instrument to an audio entry
  const handleInstrumentAdd = (audioIndex) => {
    const newAudios = [...audios];
    newAudios[audioIndex].instruments.push({ name: "", tuning: "", isNew: true });
    setAudios(newAudios);
  };

  // Remove an instrument from an audio entry
  const handleInstrumentRemove = (audioIndex, instrumentIndex) => {
    const newAudios = [...audios];
    newAudios[audioIndex].instruments = newAudios[audioIndex].instruments.filter((_, i) => i !== instrumentIndex);
    setAudios(newAudios);
  };

  // Handle instrument change
  const handleInstrumentChange = (audioIndex, instrumentIndex, field, value) => {
    const newAudios = [...audios];
    newAudios[audioIndex].instruments[instrumentIndex] = {
      ...newAudios[audioIndex].instruments[instrumentIndex],
      [field]: value,
    };
    setAudios(newAudios);
  };

  const handlePrintAdd = () => {
    setPrints([
      ...prints,
      {
        license: "",
        edited: false,
        url: "",
        file: null,
      },
    ]);
  };

  const handlePrintRemove = (index) => {
    const updatedPrints = [...prints];
    updatedPrints.splice(index, 1);
    setPrints(updatedPrints);
  };

  const handleAudioAdd = () => {
    setAudios([
      ...audios,
      {
        license: "",
        edited: false,
        url: "",
        performer: "",
        file: null,
        instruments: [],
      },
    ]);
  };

  const handleAudioRemove = (index) => {
    const updatedAudios = [...audios];
    updatedAudios.splice(index, 1);
    setAudios(updatedAudios);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const missing = canSave();
  
    if (missing.length === 0) {
      setSubmitDisabled(true);
      setIsResponseLoading(true);

      const audios_payload = audios.map(audio => ({
        ...audio,
        instruments: audio.instruments.map(instrument => {
          if (instrument.isNew) {
            return {
              name: instrument.name,
              tuning: instrument.tuning,
            };
          }
          return { id: instrument.id };
        }),
      }));


      var formData = new FormData();

      // composer
      if (isAddingNewComposer) {
        formData.append("composer", newComposer);
      } else {
        formData.append("composer", composersData.entities[composer].name);
      }
      // score title
      formData.append("title", title);

      // files
      scoreFiles.forEach((file) => {
        formData.append("files", file.file);
      });

      // prints
      prints.forEach((print) => {
        formData.append("files", print.file);
      });

      // audios
      audios_payload.forEach((audio) => {
        formData.append("files", audio.file);
      });

      // send form data with axios
      const response_files = await axios.post("http://localhost:3500/scores/upload-files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "authorization": `Bearer ${token}`
        }
      });

      if (response_files.status !== 200) {
        console.log("Error uploading files");
        setOpenErrorDialog(true);
        return;
      }
      
      const response = await addNewScore({
        title,
        composer: isAddingNewComposer ? { name: newComposer, bio: newComposerBio } : { id : composer },
        license: scoreLicense,
        url: scoreUrl,
        edited: scoreFiles.some(file => file.edited),
        prints: prints.map(print => ({
          license: print.license,
          filename: print.file.name,
          edited: print.edited,
          url: print.url,
        })),
        audios: audios_payload.map(audio => ({
          license: audio.license,
          filename: audio.file.name,
          edited: audio.edited,
          url: audio.url,
          performer: audio.performer,
          instruments: audio.instruments,
        })),
        filenames: scoreFiles.map(score => score.file.name),
        username: username,
      });

      setIsResponseLoading(false);

      if (response.error) {
        console.log(response.error);
        setSubmitDisabled(false);
        setOpenErrorDialog(true);
      }

      // Open dialog if successful
      if (response.data) {
        setOpenSuccess(true);
        setSubmitDisabled(false);

        // Clear form
        setTitle("");
        setComposer("");
        setNewComposer("");
        setNewComposerBio("");
        setScoreUrl("");
        setScoreFiles([]);
        setPrints([]);
        setAudios([]);
        setInstruments([]);
        setOpenSuccess(true);
      } 
    } else {
      setMissingFields(missing);
      setOpenMissingFieldsDialog(true);
    }

    
  };

  const renderFieldError = (field) => {
    return !field ? { borderColor: 'red' } : {};
  };

  const handleClose = () => {
    setOpenSuccess(false);
    navigate("/dash/scores");
  };

  let content;

  if (isLoading || isComposersLoading || isInstrumentsLoading || isResponseLoading) {
    content = (
      <section className="dash-container ">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  }

  if (isComposersSuccess && isInstrumentsSuccess) {
    content = (
      <section className="dash-container">
        <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
          <Typography variant="h3" align="center" mb={2}>
            Add a new Piece to MUSCAT
          </Typography>

          <form>
            {/* Score Section */}
            <Box mb={3}>
              <Typography variant="h5" align="center">Score</Typography>
              <Divider sx={{ marginBottom: 2 }} />

              <Typography variant="p">Title</Typography>
              <TextField
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={renderFieldError(title)}
                placeholder="Score Title"
              />

              <Typography variant="p">Composer</Typography>
              <Select
                fullWidth
                value={isAddingNewComposer ? "new" : composer}
                onChange={(e) => {
                  if (e.target.value === "new") {
                    setIsAddingNewComposer(true);
                    setComposer("");
                  } else {
                    setIsAddingNewComposer(false);
                    setComposer(e.target.value);
                  }
                }}
                displayEmpty
                style= {{ marginBottom: 10 }}
              >
                <MenuItem value="" disabled>Select Composer</MenuItem>
                <MenuItem value="new"> <strong>Add New Composer</strong></MenuItem>
                {composersData?.ids.map((id) => (
                  <MenuItem key={id} value={id}>
                    {composersData.entities[id].name}
                  </MenuItem>
                ))}
              </Select>

              {isAddingNewComposer && (
                <>
                  <TextField
                    fullWidth
                    value={newComposer}
                    onChange={(e) => setNewComposer(e.target.value)}
                    placeholder="Composer Name"
                    style={{ ...renderFieldError(newComposer), marginBottom: 10 }}
                  />
                  <TextField
                    fullWidth
                    value={newComposerBio}
                    onChange={(e) => setNewComposerBio(e.target.value)}
                    placeholder="Composer Biography"
                    multiline
                    rows={4}
                    style={renderFieldError(newComposerBio)}
                  />
                </>
              )}

              <Typography variant="p">License</Typography>
              <Select
                value={scoreLicense}
                onChange={(e) => setScoreLicense(e.target.value)}
                fullWidth
                displayEmpty
              >
                <MenuItem value="" disabled>Select License</MenuItem>
                {Object.keys(CONTENT_LICENSES)
                  .sort()
                  .map((optionKey) => (
                    <MenuItem key={optionKey} value={optionKey}>
                      {CONTENT_LICENSES[optionKey]}
                    </MenuItem>
                  ))}
              </Select>

              <Typography variant="p">Digital Score Source (Provide URL)</Typography>
              <TextField
                type="url"
                fullWidth
                value={scoreUrl}
                onChange={(e) => setScoreUrl(e.target.value)}
                style={renderFieldError(scoreUrl)}
                placeholder="URL"
              />
            </Box>

            {/* Score Files Section */}
            <Box mb={3} border={1} borderColor="grey.300" borderRadius={2} p={2}>
              <Typography variant="h5" align="center">Score Files</Typography>
              <Divider sx={{ marginBottom: 2 }} />

              {scoreFiles.map((file, index) => (
                <Box key={index} mb={2} ml={3}>
                  <Typography variant="p">
                    File (Score in XML, KERN, LY, or KRN format)
                  </Typography>
                  <TextField
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: ".xml,.kern,.ly,.krn" }}
                    onChange={(e) => {
                      const updatedScoreFiles = [...scoreFiles];
                      updatedScoreFiles[index] = {
                        ...updatedScoreFiles[index],
                        file: e.target.files[0],
                      };
                      setScoreFiles(updatedScoreFiles);
                    }}
                    style={renderFieldError(file.file)}
                  />

                  <Checkbox
                    checked={file.edited}
                    onChange={(e) => {
                      const updatedScoreFiles = [...scoreFiles];
                      updatedScoreFiles[index].edited = e.target.checked;
                      setScoreFiles(updatedScoreFiles);
                    }}
                  />
                  <label>Edited from original</label>
                  <br />
                  <Button
                    type="button"
                    onClick={() => handleScoreFileRemove(index)}
                    style={{ color: "red" }}
                    startIcon={<RemoveIcon />}
                  >
                    Remove Score File
                  </Button>
                </Box>
              ))}

              <Grid container justifyContent="center">
                <Button
                  type="button"
                  onClick={handleScoreFileAdd}
                  startIcon={<AddIcon />}
                >
                  Add Score File
                </Button>
              </Grid>
            </Box>

            {/* Prints Section */}
            <Box mb={3} border={1} borderColor="grey.300" borderRadius={2} p={2}>
              <Typography variant="h5" align="center">Prints</Typography>
              <Divider sx={{ marginBottom: 2 }} />

              {prints.map((print, index) => (
                <Box key={index} mb={2} ml={3}>
                  <Typography variant="p">License</Typography>
                  <Select
                    value={ print.license || "" }
                    onChange={(e) => {
                      const updatedPrints = [...prints];
                      updatedPrints[index].license = e.target.value;
                      setPrints(updatedPrints);
                    }}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Select License</MenuItem>
                    {Object.keys(CONTENT_LICENSES)
                      .sort()
                      .map((optionKey) => (
                        <MenuItem key={optionKey} value={optionKey}>
                          {CONTENT_LICENSES[optionKey]}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography variant="p">Print Source (Provide URL)</Typography>
                  <TextField
                    fullWidth
                    value={print.url}
                    placeholder="URL"
                    onChange={(e) => {
                      const updatedPrints = [...prints];
                      updatedPrints[index].url = e.target.value;
                      setPrints(updatedPrints);
                    }}
                    style={renderFieldError(print.url)}
                  />

                  <Typography variant="p">File (PDF, Image)</Typography>
                  <TextField
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: ".pdf,.jpg,.jpeg,.png,.gif" }}
                    onChange={(e) => {
                      const updatedPrints = [...prints];
                      updatedPrints[index].file = e.target.files[0];
                      setPrints(updatedPrints);
                    }}
                    style={renderFieldError(print.file)}
                  />

                  <Checkbox
                    checked={print.edited}
                    onChange={(e) => {
                      const updatedPrints = [...prints];
                      updatedPrints[index].edited = e.target.checked;
                      setPrints(updatedPrints);
                    }}
                  />
                  <label>Edited from original</label>
                  <br />
                  <Button
                    type="button"
                    onClick={() => handlePrintRemove(index)}
                    style={{ color: "red" }}
                    startIcon={<RemoveIcon />}
                  >
                    Remove Print
                  </Button>
                </Box>
              ))}

              <Grid container justifyContent="center">
                <Button
                  type="button"
                  onClick={handlePrintAdd}
                  startIcon={<AddIcon />}
                >
                  Add Print
                </Button>
              </Grid>
            </Box>

            {/* Audios Section */}
            <Box mb={3} border={1} borderColor="grey.300" borderRadius={2} p={2}>
              <Typography variant="h5" align="center">Audios</Typography>
              <Divider sx={{ marginBottom: 2 }} />

              {audios.map((audio, index) => (
                <Box key={index} mb={2} ml={3}>
                  <Typography variant="p">License</Typography>
                  <Select
                    value={audio.license || ""}
                    onChange={(e) => {
                      const updatedAudios = [...audios];
                      updatedAudios[index].license = e.target.value;
                      setAudios(updatedAudios);
                    }}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Select License</MenuItem>
                    {Object.keys(CONTENT_LICENSES)
                      .sort()
                      .map((optionKey) => (
                        <MenuItem key={optionKey} value={optionKey}>
                          {CONTENT_LICENSES[optionKey]}
                        </MenuItem>
                      ))}
                  </Select>

                  <Typography variant="p">File (MP3, WAV, etc.)</Typography>
                  <TextField
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: "audio/*" }}
                    onChange={(e) => {
                      const updatedAudios = [...audios];
                      updatedAudios[index].file = e.target.files[0];
                      setAudios(updatedAudios);
                    }}
                    style={renderFieldError(audio.file)}
                  />

                  <Typography variant="p">Performer</Typography>
                  <TextField
                    fullWidth
                    value={audio.performer}
                    placeholder="Performer Name"
                    onChange={(e) => {
                      const updatedAudios = [...audios];
                      updatedAudios[index].performer = e.target.value;
                      setAudios(updatedAudios);
                    }}
                    style={renderFieldError(audio.performer)}
                  />

                  <Typography variant="p">Audio Source (Provide URL)</Typography>
                  <TextField
                    fullWidth
                    value={audio.url}
                    placeholder="URL"
                    onChange={(e) => {
                      const updatedAudios = [...audios];
                      updatedAudios[index].url = e.target.value;
                      setAudios(updatedAudios);
                    }}
                    style={renderFieldError(audio.url)}
                  />

                  <Typography variant="p">Instruments</Typography>
                  <br/>
                  {audio.instruments.map((instrument, instrumentIndex) => (
                    <Box key={instrumentIndex} mb={2} ml={3}>
                      <Typography variant="p">Instrument</Typography>
                      <Select
                        fullWidth
                        value={instrument.isNew ? "new" : instrument.id || ""}
                        onChange={(e) => {
                          if (e.target.value === "new") {
                            handleInstrumentChange(index, instrumentIndex, "isNew", true);
                            handleInstrumentChange(index, instrumentIndex, "name", "");
                            handleInstrumentChange(index, instrumentIndex, "id", null);
                          } else {
                            handleInstrumentChange(index, instrumentIndex, "isNew", false);
                            handleInstrumentChange(index, instrumentIndex, "id", e.target.value);
                          }
                        }}
                        displayEmpty
                        style={{ marginBottom: 10 }}
                      >
                        <MenuItem value="" disabled>Select Instrument</MenuItem>
                        <MenuItem value="new"><strong> Add New Instrument</strong></MenuItem>
                        {instrumentsData?.ids.map((id) => (
                          <MenuItem key={id} value={id}>
                            {instrumentsData.entities[id].name}
                          </MenuItem>
                        ))}
                      </Select>

                      {instrument.isNew && (
                        <>
                          <TextField
                            fullWidth
                            value={instrument.name}
                            onChange={(e) => handleInstrumentChange(index, instrumentIndex, "name", e.target.value)}
                            placeholder="Instrument Name"
                            style={{ ...renderFieldError(instrument.name), marginBottom: 10 }}
                          />
                          <TextField
                            fullWidth
                            value={instrument.tuning}
                            onChange={(e) => handleInstrumentChange(index, instrumentIndex, "tuning", e.target.value)}
                            placeholder="Instrument Tuning"
                            style={renderFieldError(instrument.tuning)}
                          />
                        </>
                      )}

                      <Button
                        type="button"
                        onClick={() => handleInstrumentRemove(index, instrumentIndex)}
                        style={{ color: "red" }}
                        startIcon={<RemoveIcon />}
                      >
                        Remove Instrument
                      </Button>
                    </Box>
                  ))}

                  <Grid container justifyContent="center">
                    <Button type="button" onClick={() => handleInstrumentAdd(index)} startIcon={<AddIcon />}>
                      Add Instrument
                    </Button>
                  </Grid>
                  <Checkbox
                    checked={audio.edited}
                    onChange={(e) => {
                      const updatedAudios = [...audios];
                      updatedAudios[index].edited = e.target.checked;
                      setAudios(updatedAudios);
                    }}
                  />
                  <label>Edited from original</label>
                  <br />
                  <Button
                    type="button"
                    onClick={() => handleAudioRemove(index)}
                    style={{ color: "red" }}
                    startIcon={<RemoveIcon />}
                  >
                    Remove Audio
                  </Button>
                </Box>
              ))}

              <Grid container justifyContent="center">
                <Button
                  type="button"
                  onClick={handleAudioAdd}
                  startIcon={<AddIcon />}
                >
                  Add Audio
                </Button>
              </Grid>
            </Box>

            {/* Submit Button */}
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                Submit Score
              </Button>
            </Grid>
          </form>
        </Paper>
        {/* Dialogs */}
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Submission Successful</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Thank you for your submission! Your score has been sent for approval to be included in the dataset.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openMissingFieldsDialog} onClose={() => setOpenMissingFieldsDialog(false)}>
        <DialogTitle>Please fill in the following fields</DialogTitle> 
        <DialogContent>
            {missingFields.map((field, index) => (
              <Typography key={index}>{field}</Typography>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMissingFieldsDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)}>
        <DialogTitle>Error Submitting Score</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There was an unexpected error submitting your score. Please try again later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </section>
    );
  }

  return content;
};

export default NewScoreForm;