import React, { useState, useEffect } from "react";
import { useGetInstrumentsQuery } from "../features/instruments/instrumentsApiSlice";
import { Input, IconButton, List, ListItem, ListItemText } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const InstrumentSearchBar = ({ onSearch }) => {
  const navigate = useNavigate();
  const {
    data: instruments,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetInstrumentsQuery("instrumentsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [instrumentResults, setInstrumentResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTriggered, setSearchTriggered] = useState(false);

  useEffect(() => {
    if (searchQuery && isSuccess) {
      const instrumentNames = Object.values(instruments.entities).map(
        (instrument) => instrument.name
      );
      const results = instrumentNames.filter((instrument) =>
        instrument.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setInstrumentResults(results.slice(0, 5)); // Limit to first 5 results
      setShowSuggestions(results.length > 0);
    } else {
      setInstrumentResults([]);
      setShowSuggestions(false);
    }
  }, [searchQuery, isSuccess, instruments]);

  const handleSearchClick = () => {
    setSearchTriggered(true);
    const results = Object.values(instruments.entities)
      .filter((instrument) =>
        instrument.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map((instrument) => instrument.id);
    onSearch(results, searchQuery);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (instrumentId) => {
    navigate(`/dash/instruments/${instrumentId}`);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          id="instrumentSearch"
          placeholder="Type an instrument name..."
          value={searchQuery}
          style={{ width: '100%' }}
          onChange={(e) => {
            const query = e.target.value;
            setSearchQuery(query);
            if (query === '') {
              onSearch([], query); // Clear suggestions when search query is empty
              setInstrumentResults([]);
              setShowSuggestions(false);
              setSearchTriggered(false);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchClick();
            }
          }}
        />
        <IconButton onClick={handleSearchClick}>
          <SearchIcon />
        </IconButton>
      </div>
      {showSuggestions && (
        <List>
          {instrumentResults.map((instrument, index) => {
            const instrumentEntity = Object.values(instruments.entities).find(
              (entity) => entity.name === instrument
            );
            return (
              <ListItem
                key={index}
                button
                onClick={() => handleSuggestionClick(instrumentEntity.id)}
              >
                <ListItemText primary={instrument} />
              </ListItem>
            );
          })}
        </List>
      )}
      {searchTriggered && searchQuery && instrumentResults.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Sorry, no results for "{searchQuery}", try searching a different instrument.
        </div>
      )}
      {searchTriggered && searchQuery && instrumentResults.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Results for "{searchQuery}"
        </div>
      )}
    </>
  );
};

export default InstrumentSearchBar;