import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from '@mui/material/Divider';
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";
import { Link } from "react-router-dom";
import LaunchIcon from '@mui/icons-material/Launch';
import AudioFileRoundedIcon from '@mui/icons-material/AudioFileRounded';
import Paper from "@mui/material/Paper";

const imagesData = [
  {
    src: "https://i.imgur.com/pACFYAG.png",
    alt: "Logo Ministerio Ciencia e Innovacion",
  },
  {
    src: "https://i.imgur.com/rmGa5x2.jpg",
    alt: "Logo Agencia Estatal de Investigacion",
  },
  {
    src: "https://i.imgur.com/EXN17e4.png",
    alt: "Logo Instituto Universitario de Investigación Informática",
  },
  {
    src: "https://i.imgur.com/sxuAHm4.png",
    alt: "Logo Departamento de Lenguajes y Sistemas Informáticos",
  },
  {
    src: "https://i.imgur.com/Iu7CvC1.png",
    alt: "Logo Pattern Recognition and Artificial Intelligence Group",
  },
];

const Public = () => {
  const content = (
    <>
      <PublicHeader onLogin={false} />

      <main>
        <Box
          sx={{
            bgcolor: "inherit",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="md">
            <Grid padding="2rem" component={Paper}>
            <Typography
              component="h1"
              variant="h3"
              sx={{ fontWeight: 500 }}
              align="center"
              gutterBottom
            >
              MUSCAT
            </Typography>

            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
               <span style={{ fontWeight: 600 }}>M</span>ultimodal 
               M<span style={{ fontWeight: 600 }}>us</span>ic 
               <span style={{ fontWeight: 600 }}> C</span>ollection for  
               <span style={{ fontWeight: 600 }}> A</span>utomatic 
               <span style={{ fontWeight: 600 }}> T</span>ranscription
            </Typography>

            <Box m={2}> {/* Applying margin here */}
              <Divider />
            </Box>

            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              Where Optical Music Recognition and Automatic Music Transcription meet.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button
                variant="contained"
                to="/login"
                component={Link}
                startIcon={<AudioFileRoundedIcon />}
                style={{ color: 'white' }}
              >
                Access the Corpus
              </Button> 
              <Button
                style={{ color: 'black' }}
                variant="outlined"
                component="a"
                href="https://sites.google.com/view/multiscore-project"
                target="_blank"
                startIcon={<LaunchIcon />}
              >
                More about the project
              </Button>
            </Stack>
            </Grid>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4} columns={15}>
            {imagesData.map((logo) => (
              <Grid item key={logo} xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: "56.25%",
                    }}
                    image={logo.src}
                    src="img"
                    alt={logo.alt}
                    // adjust image to the cardmedia
                    style={{
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      margin: "10%",
                    }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <PublicFooter />
    </>
  );
  return content;
};
export default Public;
