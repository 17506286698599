import { createTheme } from "@mui/material/styles";

// Color variables
const frenchBlue = "#1c77c3ff";
const pictonBlue = "#39a9dbff";
const aero = "#40bcd8ff";
const carrotOrange = "#f39237ff";
const persianRed = "#d63230ff";

// Material-UI theme
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#82a6a1',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#333333',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Arial','Roboto',"Segoe UI"]
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff', 
          color: 'black',
        },
      },
    },
  },
});
export default theme;
