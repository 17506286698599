import { useNavigate } from "react-router-dom";
import { useGetInstrumentsQuery } from "./instrumentsApiSlice";
import { memo } from "react";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import useAuth from "../../hooks/useAuth";

const Instrument = ({ instrumentId }) => {
  const { instrument } = useGetInstrumentsQuery("instrumentsList", {
    selectFromResult: ({ data }) => ({
      instrument: data?.entities[instrumentId],
    }),
  });

  const { isAdmin, isEmployee } = useAuth();

  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/dash/instruments/${instrumentId}`);
  };

  if (instrument) {
    const handleEdit = (e) => {
      e.stopPropagation(); // Prevent the row click event
      navigate(`/dash/instruments/edit/${instrumentId}`);
    };

    return (
      <TableRow onClick={handleRowClick} className="table-row">
        <TableCell>{instrument.name}</TableCell>
        <TableCell>{instrument.tuning}</TableCell>
        {(isAdmin || isEmployee) && (
          <TableCell>
            <IconButton color="primary" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  } else return null;
};

const memoizedInstrument = memo(Instrument);

export default memoizedInstrument;