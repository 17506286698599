import { useState, useEffect } from "react";
import { useUpdateInstrumentMutation, useDeleteInstrumentMutation } from "./instrumentsApiSlice";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";

const INSTRUMENT_REGEX = /^[A-z]{3,20}/;
const TUNING_REGEX = /^[a-zA-Z]{1,2}$/;

const EditInstrumentForm = ({ instrument }) => {
  const [updateInstrument, { isLoading, isSuccess, isError, error }] = useUpdateInstrumentMutation();
  const [deleteInstrument, { isSuccess: isDelSuccess, isError: isDelError, error: delerror }] = useDeleteInstrumentMutation();
  const navigate = useNavigate();

  const [instrumentname, setInstrumentname] = useState(instrument.name);
  const [validInstrumentname, setValidInstrumentname] = useState(false);
  const [tuning, setTuning] = useState(instrument.tuning);
  const [validInstrumentTuning, setValidInstrumentTuning] = useState(false);

  useEffect(() => {
    setValidInstrumentname(INSTRUMENT_REGEX.test(instrumentname));
  }, [instrumentname]);

  useEffect(() => {
    setValidInstrumentTuning(TUNING_REGEX.test(tuning));
  }, [tuning]);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setInstrumentname("");
      setTuning("");
      navigate("/dash/instruments");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onInstrumentnameChanged = (e) => setInstrumentname(e.target.value);
  const onInstrumentTuningChanged = (e) => setTuning(e.target.value);

  const onSaveInstrumentClicked = async () => {
    if (validInstrumentname && validInstrumentTuning) {
      await updateInstrument({ id: instrument.id, name: instrumentname, tuning: tuning });
    }
  };

  const onDeleteInstrumentClicked = async () => {
    await deleteInstrument({ id: instrument.id });
  };

  const canSave = [validInstrumentname, validInstrumentTuning].every(Boolean) && !isLoading;

  const errClass = (isError || isDelError) ? "errmsg" : "offscreen";
  const validInstrumentClass = !validInstrumentname ? "form__input--incomplete" : "";
  const validInstrumentTuningClass = !validInstrumentTuning ? "form__input--incomplete" : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let content;

  if (isLoading) {
    content = (
      <section className="dash-container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  } else {
    content = (
      <section className="dash-container">
        <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
          <Typography variant="h3" align="center" mb={2}>
            Edit Instrument
          </Typography>

          <form>
            <Typography variant="h5">Instrument</Typography>
            <Typography variant="p">Name</Typography>
            <TextField
              fullWidth
              value={instrumentname}
              onChange={onInstrumentnameChanged}
              error={!validInstrumentname}
              helperText={!validInstrumentname ? "Name must be 3-20 letters" : ""}
            />
            <Typography variant="p">Tuning</Typography>
            <TextField
              fullWidth
              value={tuning}
              onChange={onInstrumentTuningChanged}
              error={!validInstrumentTuning}
              helperText={!validInstrumentTuning ? "Tuning must be 1-2 letters" : ""}
            />

            <Button
              type="button"
              onClick={onSaveInstrumentClicked}
              disabled={!canSave}
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Save Instrument
            </Button>
            <Button
              type="button"
              onClick={onDeleteInstrumentClicked}
              variant="contained"
              color="secondary"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              Delete Instrument
            </Button>
          </form>
          {isError && <p className="errmsg">{error?.data?.message}</p>}
          {isDelError && <p className="errmsg">{delerror?.data?.message}</p>}
        </Paper>
      </section>
    );
  }

  return content;
};

export default EditInstrumentForm;