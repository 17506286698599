import { Outlet } from "react-router-dom";
import DashHeader from "./DashHeader";
import DashFooter from "./DashFooter";
import { Box, Container, Typography } from "@mui/material";

const DashLayout = () => {
  return (
    <>
      <DashHeader />

      <Outlet />

      <DashFooter />
    </>
  );
};
export default DashLayout;
