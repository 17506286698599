import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const instrumentsAdapter = createEntityAdapter({})

const initialState = instrumentsAdapter.getInitialState()

export const instrumentsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInstruments: builder.query({
            query: () => ({
                url: '/instruments',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedInstruments = responseData.map(instrument => {
                    instrument.id = instrument._id
                    return instrument
                });
                return instrumentsAdapter.setAll(initialState, loadedInstruments)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Instrument', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Instrument', id }))
                    ]
                } else return [{ type: 'Instrument', id: 'LIST' }]
            }
        }),
        addNewInstrument: builder.mutation({
            query: initialInstrumentData => ({
                url: '/instruments',
                method: 'POST',
                body: {
                    ...initialInstrumentData,
                }
            }),
            invalidatesTags: [
                { type: 'Instrument', id: "LIST" }
            ]
        }),
        updateInstrument: builder.mutation({
            query: initialInstrumentData => ({
                url: '/instruments',
                method: 'PATCH',
                body: {
                    ...initialInstrumentData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Instrument', id: arg.id }
            ]
        }),
        deleteInstrument: builder.mutation({
            query: ({ id }) => ({
                url: `/instruments`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Instrument', id: arg.id }
            ]
        }),
        getInstrument: builder.query({
            query: id => ({
                url: `/instruments/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const instrument = responseData
                instrument.id = instrument._id
                return instrument
            },
            providesTags: (result, error, arg) => [{ type: 'Instrument', id: arg }]
        }),
    }),
})

export const {
    useGetInstrumentsQuery,
    useAddNewInstrumentMutation,
    useUpdateInstrumentMutation,
    useDeleteInstrumentMutation,
    useGetInstrumentQuery,
} = instrumentsApiSlice

// returns the query result object
export const selectInstrumentsResult = instrumentsApiSlice.endpoints.getInstruments.select()

// creates memoized selector
const selectInstrumentsData = createSelector(
    selectInstrumentsResult,
    instrumentsResult => instrumentsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllInstruments,
    selectById: selectInstrumentById,
    selectIds: selectInstrumentIds
    // Pass in a selector that returns the instruments slice of state
} = instrumentsAdapter.getSelectors(state => selectInstrumentsData(state) ?? initialState)