import { useParams } from 'react-router-dom'
import EditScoreForm from './EditScoreForm'
import { useGetScoreQuery } from './scoresApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import { Grid } from '@mui/material'

const EditScore = () => {
    useTitle('techScores: Edit Score')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const {
        data: score,
        isLoading: isScoreLoading,
        isSuccess: isScoreSuccess,
        isError: isScoreError,
        error: scoreError,
      } = useGetScoreQuery(id);

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!score || !users?.length || isScoreLoading) return (
        <section className="dash-container">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <PulseLoader color={"#82a6a1"} />
          </Grid>
        </section>
    );


    if (!isManager && !isAdmin) {
        if (score.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditScoreForm score={score} users={users} />

    return content
}
export default EditScore