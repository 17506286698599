import React, { useEffect, useState } from 'react';
import { useUpdateUserMutation } from './usersApiSlice';
import { Paper, Typography, Grid, Button, TextField } from "@mui/material";
import useTitle from '../../hooks/useTitle';
import useAuth from "../../hooks/useAuth";
import { useGetUserByUsernameQuery } from './usersApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';

const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/;

const UserMain = () => {
    useTitle("MUSCAT - My Account");

    const { username, roles } = useAuth();
    const [updateUser, { isLoading, isSuccess, isError, error }] = useUpdateUserMutation();

    const {  
        data : user,   
        isLoading: userLoading,
        isSuccess: userSuccess,
        isError: userError,
    } = useGetUserByUsernameQuery(username);

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [validNewPassword, setValidNewPassword] = useState(false);
    const [validConfirmNewPassword, setValidConfirmNewPassword] = useState(false);

    useEffect(() => {
        setValidNewPassword(PWD_REGEX.test(newPassword));
        setValidConfirmNewPassword(newPassword === confirmNewPassword);
    }, [newPassword, confirmNewPassword]);

    const onNewPasswordChanged = (e) => setNewPassword(e.target.value);
    const onConfirmNewPasswordChanged = (e) => setConfirmNewPassword(e.target.value);

    const onSavePasswordClicked = async () => {
        if (validNewPassword && validConfirmNewPassword) {
            await updateUser({ id: user._id ,username, password: newPassword, roles, active: user.active });
        }
    };

    const canSave = [validNewPassword, validConfirmNewPassword].every(Boolean);

    if (userLoading || isLoading) {
        return (
            <section className="dash-container">
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <PulseLoader color={"#82a6a1"} />
              </Grid>
            </section>
          );
    }

    if (userError || isError) {
        return (
            <section className="dash-container">
                <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
                    <Typography variant="h3" align="center">
                        My Account
                    </Typography>
                    <p className="errmsg">{error?.data?.message}</p>
                </Paper>
            </section>
        );
    }

    if (userSuccess || isSuccess) {
        return (
            <section className="dash-container">
                <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
                    <Typography variant="h3" align="center">
                        My Account
                    </Typography>
                    
                    <Grid container spacing={2} style={{ marginTop: "1em", marginBottom: "1em" }}>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">
                                Username: {username}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">
                                {roles.length === 1 ? "Role: " : "Roles: "}
                                {roles.join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Form to change password */}
                    <Typography variant="h5" align="center">
                        Change Password
                    </Typography>
                    <form>
                        <TextField
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={onNewPasswordChanged}
                            error={!validNewPassword}
                            helperText={!validNewPassword ? "Password must be 4-12 chars incl. !@#$%" : ""}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Confirm New Password"
                            type="password"
                            value={confirmNewPassword}
                            onChange={onConfirmNewPasswordChanged}
                            error={!validConfirmNewPassword}
                            helperText={!validConfirmNewPassword ? "Passwords must match" : ""}
                            margin="normal"
                        />
                        <Button
                            type="button"
                            onClick={onSavePasswordClicked}
                            disabled={!canSave}
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "10px" }}
                        >
                            Save Password
                        </Button>
                    </form>
                    {isError && <p className="errmsg">{error?.data?.message}</p>}
                </Paper>
            </section>
        );
    }
};

export default UserMain;