import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetScoreQuery } from './scoresApiSlice';
import { useApproveScoreMutation } from './scoresApiSlice';
import { useRejectScoreMutation } from './scoresApiSlice';
import { useGetScoreComposerQuery } from './scoresApiSlice';
import { useGetScoresFilesQuery } from './scoresApiSlice';
import { useGetPrintFilesQuery } from './scoresApiSlice';
import { useGetAudioFilesQuery } from './scoresApiSlice';
import { useGetCutsDataQuery } from './scoresApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useTitle from '../../hooks/useTitle';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import useAuth from '../../hooks/useAuth';

const ScoreSubmission = () => {
  useTitle("MultiScore: Score Details");
  const { id } = useParams();
  const { isAdmin, isEmployee } = useAuth();

  const [approveScore] = useApproveScoreMutation();
  const [rejectScore] = useRejectScoreMutation();
  const [open, setOpenSuccess] = useState(false);
  const [openConfirmReject, setOpenConfirmReject] = useState(false);

  const b64toURL= (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return URL.createObjectURL(blob);
  }

  const ClickApprove = () => {
    approveScore(id);
    setOpenSuccess(true);
  };

  const ClickReject = () => {
    rejectScore(id);
    setOpenConfirmReject(false);
    setOpenSuccess(true);
  };

  const {
    data: score,
    isLoading: isScoreLoading,
    isSuccess: isScoreSuccess,
    isError: isScoreError,
    error: scoreError,
  } = useGetScoreQuery(id);

  const {
    data: composer,
    isLoading: isComposerLoading,
    isSuccess: isComposerSuccess,
    isError: isComposerError,
    error: composerError,
  } = useGetScoreComposerQuery(id);

  const [isScoresExpanded, setIsScoresExpanded] = useState(false);
  const [isPrintsExpanded, setIsPrintsExpanded] = useState(false);
  const [isAudiosExpanded, setIsAudiosExpanded] = useState(false);
  const [isCutsExpanded, setIsCutsExpanded] = useState(false);

  const {
    data: score_files,
    isLoading: isScoresDataLoading,
    isSuccess: isScoresDataSuccess,
    isError: isScoresDataError,
    error: scoresDataError,
  } = useGetScoresFilesQuery(id, { skip: !isScoresExpanded });

  const {
    data: print_files,
    isLoading: isPrintsDataLoading,
    isSuccess: isPrintsDataSuccess,
    isError: isPrintsDataError,
    error: printsDataError,
  } = useGetPrintFilesQuery(id, { skip: !isPrintsExpanded });

  const {
    data: audio_files,
    isLoading: isAudiosDataLoading,
    isSuccess: isAudiosDataSuccess,
    isError: isAudiosDataError,
    error: audiosDataError,
  } = useGetAudioFilesQuery(id, { skip: !isAudiosExpanded });

  const {
    data: cuts_data,
    isLoading: isCutsLoading,
    isSuccess: isCutsSuccess,
    isError: isCutsError,
    error: cutsError,
  } = useGetCutsDataQuery(id, { skip: !isCutsExpanded });

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // alert('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  if (isScoreLoading || isComposerLoading) {
    return (
      <section className="dash-container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  }

  // kick out if not admin
  if (!isAdmin) {
    return (
      <section className="dash-container">
        <Paper
          style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}
        >
          <Typography variant="h3" align="center">
            You are not authorized to view this page.
          </Typography>
        </Paper>
      </section>
    )
  } 

  if (isScoreError) {
    return <p className="errmsg">{scoreError?.data?.message}</p>;
  }

  if (isComposerError) {
    return <p className="errmsg">{composerError?.data?.message}</p>;
  }


  if (isScoreSuccess && isComposerSuccess) {
    return (
      <section className="dash-container">
        <Paper
          style={{ padding: "20px", margin: "20px auto", maxWidth: "800px", overflowWrap: "break-word" }}
        >
          {/* Score Section */}
          <Typography variant="h3" align="center">
            Submission: {score.title}
          </Typography>
          <Grid container spacing={2} style={{ marginTop: "1em", marginBottom: "1em" }}>
            <Grid item xs={12}>
            <Typography variant="h6">Composer</Typography>
              <Link to={`/dash/composers/${composer._id}`} target='_blank'>
                <Typography variant="body1">{composer.name}</Typography>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">License</Typography>
              <Typography variant="body1">{score.license}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">URL</Typography>
              <Typography variant="body1">
                <a href={score.url} target="_blank" rel="noopener noreferrer">
                  {score.url}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Path to Folder</Typography>
              <Typography variant="body1">{score.pathToFolder}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Edited</Typography>
              <Typography variant="body1">{score.edited ? "Yes" : "No"}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">MUSCAT Version</Typography>
              <Typography variant="body1">{score.MUSCAT_version}</Typography>
            </Grid>
            {/* Score Files Section */}
            { score.license !== "Copyright" && (
            <Grid item xs={12}>
              <Accordion onChange={() => setIsScoresExpanded(!isScoresExpanded)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="score-files-content"
                  id="score-files-header"
                >
                  <Typography variant="h6">Score Files</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {isScoresExpanded && isScoresDataLoading && (
                    <Grid container justifyContent="center">
                      <PulseLoader color={"#82a6a1"} />
                    </Grid>
                  )}
                  {isScoresExpanded && isScoresDataSuccess && score_files.data.map((text, index) => (
                    <Grid container justifyContent={"center"} key={index}>
                    <Box 
                      pt={1} pb={1} pl={2} pr={2}
                      mb={2}
                      sx={{ borderColor: '#82a6a1', borderRadius: 1, border: 1, width: '90%' }}
                    >
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={11}>
                          <Typography variant="body1" fontWeight='fontWeightMedium' mb={2} style={{ width: '100%' }}>
                            {score_files.filepaths[index]}
                          </Typography>
                          <TextField
                            multiline
                            rows={8}
                            value={text}
                            variant="outlined"
                            style={{ marginBottom: "1em" }}
                            inputProps={{ readOnly: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => copyToClipboard(text)}
                            aria-label="copy"
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  ))}
                  { isScoresDataError && (
                    <Typography variant="body1" mt={1} mb={1}>
                      Error loading score files.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            )}
            {/* Prints Section */}
            <Grid item xs={12}>
              <Accordion onChange={() => setIsPrintsExpanded(!isPrintsExpanded)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="prints-content"
                  id="prints-header"
                >
                  <Typography variant="h6">Prints</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {isPrintsExpanded && isPrintsDataLoading && (
                    <Grid container justifyContent="center">
                      <PulseLoader color={"#82a6a1"} />
                    </Grid>
                  )}
                  {isPrintsExpanded && isPrintsDataSuccess && (
                    <Grid container spacing={2} justifyContent="center">
                      {print_files.data.map((print_b64, index) => (
                        print_files.licenses[index] !== "Copyright" && (
                          <Box 
                            pt={1} pb={1} pl={2} pr={2}
                            mb = {2}
                            sx={{ borderColor: '#82a6a1', borderRadius: 1, border: 1 }}
                            width="90%"
                          >
                          <Grid container justifyContent={"center"} key={index}>
                          <Worker
                            workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"
                            key={index}
                          >

                          <Typography variant="body1" fontWeight='fontWeightMedium' mb={2} style={{ width: '100%' }}>
                            {print_files.filepaths[index]}
                          </Typography>
                          <div
                            style={{
                              height: "300px",
                              marginBottom: "8em",
                              width: "90%",
                              border: "1px solid",
                            }}
                          >
                              <Viewer fileUrl={b64toURL(print_b64, 'application/pdf')} />
                              <Typography variant="body1" mt={2}>
                                Source:
                              </Typography>
                              <a
                                href={print_files.urls[index]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {print_files.urls[index]}
                              </a>
                              <Typography variant="body1" mt={2}>
                                Edited: {print_files.edited[index] ? "Yes" : "No"}
                              </Typography>
                            </div>
                          </Worker>
                          </Grid>
                          </Box>
                        )
                      ))}
                    </Grid>
                  )}
                  { isPrintsDataError && (
                    <Typography variant="body1" mt={1} mb={1}>
                      Error loading print files.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* Audios Section */}
            <Grid item xs={12}>
              <Accordion onChange={() => setIsAudiosExpanded(!isAudiosExpanded)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="audios-content"
                  id="audios-header"
                >
                  <Typography variant="h6">Audios</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {isAudiosExpanded && isAudiosDataLoading && (
                    <Grid container justifyContent="center">
                      <PulseLoader color={"#82a6a1"} />
                    </Grid>
                  )}
                  {isAudiosExpanded && isAudiosDataSuccess &&  (
                    <Grid container spacing={2} justifyContent="center">
                      {audio_files.data.map((audio_b64, audio_index) => (
                          audio_files.licenses[audio_index] !== "Copyright" && (
                            <Box 
                              pt={1} pb={1} pl={2} pr={2}
                              mb = {2}
                              sx={{ borderColor: '#82a6a1', borderRadius: 1, border: 1 }}
                              width="90%"
                            >
                              <Typography variant="body1" fontWeight='fontWeightMedium' mb={2} style={{ width: '100%' }}>
                                {audio_files.filepaths[audio_index]}
                              </Typography>
                              <audio
                                controls
                                src={b64toURL(audio_b64, 'audio/mpeg')}
                                style={{ width: '100%' }}
                              />
                              <Typography variant="body1" mt={2}>
                                Source:
                              </Typography>
                              <a
                                href={audio_files.urls[audio_index]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {audio_files.urls[audio_index]}
                              </a>
                              <Typography variant="body1" mt={2}>
                                Performer: {audio_files.performers[audio_index]}
                              </Typography>
                              <Typography variant="body1" mt={2}>
                                License: {audio_files.licenses[audio_index]}
                              </Typography>
                              <Typography variant="body1" mt={2}>
                                Instruments:
                              </Typography>
                              {/* inrtuments_names with hyperlink to dash/instruments/id */}
                              {audio_files.instruments_ids[audio_index].map((instrument_id, index) => (
                                <Box key={index}>
                                  <Link to={`/dash/instruments/${instrument_id}`} target='_blank'>
                                    <Typography variant="body1" mt={1}>
                                      {audio_files.instruments_names[audio_index][index]}
                                    </Typography>
                                  </Link>
                                </Box>
                              ))}
                            </Box>
                          )
                      ))}
                    </Grid>
                  )}
                  { isAudiosDataError && (
                    <Typography variant="body1" mt={1} mb={1}>
                      Error loading audio files.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion onChange={() => setIsCutsExpanded(!isCutsExpanded)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="cuts-content"
                  id="cuts-header"
                >
                  <Typography variant="h6">Cuts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {isCutsExpanded && isCutsLoading && (
                    <Grid container justifyContent="center">
                      <PulseLoader color={"#82a6a1"} />
                    </Grid>
                  )}
                 {isCutsExpanded && isCutsSuccess && cuts_data.data  && (
                  <Grid container spacing={2} justifyContent="center">
                    {cuts_data.data.map((cut_data, index) => (
                      <Box 
                        pt={1} pb={1} pl={2} pr={2}
                        mb = {2}
                        sx={{ borderColor: '#82a6a1', borderRadius: 1, border: 1 }}
                        width="90%"
                      >
                        <Typography variant="body1" fontWeight="fontWeightMedium" mb={2} style={{ width: '100%' }}>
                          {cut_data.audioFilePath}
                        </Typography>
                        <Typography variant="body1" mt={2} mb={2}>
                          Number of Cuts: {cut_data.cuts.length}
                        </Typography>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="cuts-content"
                            id="cuts-header"
                          >
                            <Typography variant="body1">Times</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {cut_data.cuts.map((cut_item, cut_index) => (
                              <div key={cut_index}>
                                <Typography variant="body1" mt={1}>
                                  Cut {cut_index + 1}
                                </Typography>
                                <Typography variant="body1" mt={1} ml={2} fontWeight="fontWeightLight">
                                  Start: {cut_item.start} s
                                </Typography>
                                <Typography variant="body1" mt={1} ml={2} fontWeight="fontWeightLight">
                                  End: {cut_item.end} s
                                </Typography>
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ))}
                  </Grid>
                )}
                { isCutsExpanded && isCutsSuccess && !cuts_data.data && (
                  <Typography variant="body1" mt={1} mb={1}>
                    No cuts for this score.
                  </Typography>
                )}
                { isCutsError && (
                  <Typography variant="body1" mt={1} mb={1}>
                    Error loading cuts data.
                  </Typography>
                )}
                
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          {/* If the status is not approved and the user is employee or admin */}
          { (score.status !== "approved") && ((isAdmin || isEmployee)) && (
            // score creator
            <div>
            <Typography variant="body1" mt={2} mb={2}>
              Submitted by: {score.creator}
            </Typography>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                {/* Buttons to approve or reject submission */}
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "1em" }}
                  onClick={() => ClickApprove()}
                >
                  Approve
                </Button>
                { score.status !== "rejected" && (
                  <Button
                    variant="contained" 
                    color="secondary"
                    onClick={() => setOpenConfirmReject(true)}
                  >
                    Reject
                  </Button>
                )}
              </Grid>
            </Grid>
            </div>
          )}
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                component={Link}
                to="/dash/scores/submissions"
                startIcon={<ArrowBackIcon />}
              >
                Back to Submissions
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {/* Dialog for submission rejected or approved */}
        <Dialog
          open={open}
          onClose={() => setOpenSuccess(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {score.status === "approved" ? "Approved" : "Rejected"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {score.status === "approved"
                ? "The score has been approved."
                : "The score has been rejected."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSuccess(false)} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog to confirm reject */}
        <Dialog
          open={openConfirmReject}
          onClose={() => setOpenConfirmReject(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Reject Score?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to reject this score?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmReject(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={ClickReject} color="secondary" autoFocus>
              Reject
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    );
  }

  return null;
};

export default ScoreSubmission;