import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetComposerQuery } from './composersApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  Paper,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useTitle from '../../hooks/useTitle';

const ComposerMain = () => {
  useTitle("MultiScore: Composer Details");
  const { id } = useParams();

  const {
    data: composer,
    isLoading: isComposerLoading,
    isSuccess: isComposerSuccess,
    isError: isComposerError,
    error: composerError,
  } = useGetComposerQuery(id);

  if (isComposerLoading) {
    return (
      <section className="dash-container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  }

  if (isComposerError) {
    return <p className="errmsg">{composerError?.data?.message}</p>;
  }

  if (isComposerSuccess) {
    return (
      <section className="dash-container">
        <Paper
          style={{ padding: "20px", margin: "20px auto", maxWidth: "800px", textAlign: "center" }}
        >
          <Typography variant="h3" align="center">
            {composer.name}
          </Typography>
          <img
            src={`${composer.image}`}
            alt={composer.name}
            style={{ width: "50%", height: "auto", marginTop: "1em", borderRadius: "10%" }}
          />
          <Grid container spacing={2} style={{ marginTop: "1em", marginBottom: "1em" }}>
            <Grid item xs={12}>
              <Typography variant="h6">Biography</Typography>
              <Typography variant="body1" style={{ textAlign: 'justify' }}>
                {composer.bio}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                component={Link}
                to="/dash/composers"
                startIcon={<ArrowBackIcon />}
              >
                Back to Composers List
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </section>
    );
  }

  return null;
};

export default ComposerMain;