import { useNavigate } from "react-router-dom";
import { useGetScoresQuery } from "./scoresApiSlice";
import { useGetComposersQuery } from "../composers/composersApiSlice";
import { memo } from "react";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";

const Score = ({ scoreId, redirectToSubmissions }) => {
  const { username, isAdmin, isEmployee, isVisitor } = useAuth();
  const { score, isLoading: isScoreLoading } = useGetScoresQuery("scoresList", {
    selectFromResult: ({ data }) => ({
      score: data?.entities[scoreId],
    }),
  });
  const { composer, isLoading: isComposerLoading } = useGetComposersQuery("composersList", {
    selectFromResult: ({ data }) => ({
      composer: data?.entities[score?.composer],
    }),
  });

  const navigate = useNavigate();

  const handleRowClick = () => {
    if (redirectToSubmissions) navigate(`/dash/scores/submissions/${scoreId}`);
    else navigate(`/dash/scores/${scoreId}`);
  };

  if (isComposerLoading || isScoreLoading) {
    return (
      <TableRow>
        <TableCell colSpan={3}>
          <PulseLoader size={10} color={"#82a6a1"} />
        </TableCell>
      </TableRow>
    );
  }

  if (score) {
    const handleEdit = (e) => {
      e.stopPropagation(); // Prevent the row click event
      navigate(`/dash/scores/edit/${scoreId}`);
    };

    if (!composer) {
      return (
        <TableRow onClick={handleRowClick} className="table-row">
          <TableCell>{score.title}</TableCell>
          <TableCell>Unknown Composer</TableCell>
          {(isAdmin || isEmployee) && (
            <TableCell>
              <IconButton color="primary" onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      );
    }

    return (
      <TableRow onClick={handleRowClick} className="table-row">
        <TableCell>{score.title}</TableCell>
        <TableCell>{composer.name}</TableCell>
        {isAdmin && (
          <TableCell>
            <IconButton color="primary" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  } else {
    return (
      <TableRow className="table-row">
        <TableCell colSpan={3}>
          <PulseLoader size={10} color={"#82a6a1"} />
        </TableCell>
      </TableRow>
    );
  }
};

const memoizedScore = memo(Score);

export default memoizedScore;