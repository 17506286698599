import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Public from "./components/Public";
import Login from "./features/auth/Login";
import DashLayout from "./components/DashLayout";
import Welcome from "./features/auth/Welcome";
import ScoresList from "./features/scores/ScoresList";
import UsersList from "./features/users/UsersList";
import EditUser from "./features/users/EditUser";
import NewUserForm from "./features/users/NewUserForm";
import EditScore from "./features/scores/EditScore";
import NewScore from "./features/scores/NewScore";
import ScoreSubmissionList from "./features/scores/ScoresSubmissionsList";
import ScoreSubmission from "./features/scores/ScoreSubmission";
import ComposersList from "./features/composers/ComposersList";
import EditComposer from "./features/composers/EditComposer";
import NewComposerForm from "./features/composers/NewComposerForm";
import InstrumentsList from "./features/instruments/InstrumentsList";
import EditInstrument from "./features/instruments/EditInstrument";
import NewInstrumentForm from "./features/instruments/NewInstrumentForm";
import Analytics from "./features/analytics/Analytics";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";
import ScoreMain from "./features/scores/ScoreMain";
import InstrumentMain from "./features/instruments/InstrumentMain";
import ComposerMain from "./features/composers/ComposerMain";
import UserMain from "./features/users/UserMain";

function App() {
  useTitle("MultiScore - MUSCAT");

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
          >
            <Route element={<Prefetch />}>
              <Route path="dash" element={<DashLayout />}>
                <Route index element={<Welcome />} />

                <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.Employee, ROLES.Admin]} />
                  }
                >
                  <Route path="users">
                    <Route index element={<UsersList />} />
                    {/* only allow admins to edit and create */}
                    <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                      <Route path="edit/:id" element={<EditUser />} />
                      <Route path="new" element={<NewUserForm />} />
                    </Route>
                  </Route>
                </Route>
              
                <Route path="account" element={<UserMain />} />
              
                <Route path="scores">
                  <Route index element={<ScoresList />} />
                  <Route path="edit/:id" element={<EditScore />} />
                  <Route path=":id" element={<ScoreMain />} />
                  <Route path="new" element={<NewScore />} />
                  <Route path="submissions" element={<ScoreSubmissionList />} />
                  <Route path="submissions/:id" element={<ScoreSubmission />} />
                </Route>

                <Route path="composers">
                  <Route index element={<ComposersList />} />
                  <Route path="edit/:id" element={<EditComposer />} />
                  <Route path=":id" element={<ComposerMain />} />
                  <Route path="new" element={<NewComposerForm />} />
                </Route>

                <Route path="instruments">
                  <Route index element={<InstrumentsList />} />
                  <Route path="edit/:id" element={<EditInstrument />} />
                  <Route path=":id" element={<InstrumentMain />} />
                  <Route path="new" element={<NewInstrumentForm />} />
                </Route>

                <Route path="analytics" element={<Analytics />} />
              </Route>
              {/* End Dash */}
            </Route>
          </Route>
        </Route>
        {/* End Protected Routes */}
      </Route>
    </Routes>
  );
}

export default App;
