import { useGetUsersQuery } from "./usersApiSlice";
import User from "./User";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import useAuth from "../../hooks/useAuth";
import AddIcon from "@mui/icons-material/Add";

const UsersList = () => {
  useTitle("MultiScore: Users List");

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery("usersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { isAdmin } = useAuth();

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = users;

    const tableContent =
      ids?.length && ids.map((userId) => <User key={userId} userId={userId} />);

    content = (
      <>
        <section className="dash-container">
          <Paper
            style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}
          >
            <Typography variant="h3" align="center" mb={2}>
              Users
            </Typography>

            {isAdmin && (
              <p style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  to="/dash/users/new"
                  component={Link}
                  startIcon={<AddIcon />}
                  style={{
                    color: "white",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Add New User
                </Button>
              </p>
            )}

            <TableContainer style={{ width: "auto" }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ backgroundColor: "gray", color: "white" }}
                    >
                      Username
                    </TableCell>
                    <TableCell
                      style={{ backgroundColor: "gray", color: "white" }}
                    >
                      Roles
                    </TableCell>
                    {isAdmin && (
                      <TableCell
                        style={{ backgroundColor: "gray", color: "white" }}
                      >
                        Edit
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>{tableContent}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </section>
      </>
    );
  }

  return content;
};
export default UsersList;
