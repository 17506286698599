import React, { useState, useEffect } from "react";
import { useUpdateScoreMutation, useDeleteScoreMutation } from "./scoresApiSlice";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
} from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";
import { useGetComposersQuery } from "../composers/composersApiSlice"; // Assuming you have a composersApiSlice
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const EditScoreForm = ({ score }) => {
  const [updateScore, { isLoading, isSuccess, isError, error }] = useUpdateScoreMutation();
  const [deleteScore, { isSuccess: isDelSuccess, isError: isDelError, error: delerror }] = useDeleteScoreMutation();
  const navigate = useNavigate();

  const [title, setTitle] = useState(score.title);
  const [composer, setComposer] = useState(score.composer);
  const [url, setUrl] = useState(score.url);
  const [license, setLicense] = useState(score.license);
  const [edited, setEdited] = useState(score.edited);
  const [status, setStatus] = useState(score.status); // New state for status

  const [open, setOpen] = useState(false);

  const {
    data: composers,
    isLoading: isComposersLoading,
    isSuccess: isComposersSuccess,
    isError: isComposersError,
    error: composersError,
  } = useGetComposersQuery("composersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setTitle("");
      setComposer("");
      setUrl("");
      setLicense("");
      setEdited(false);
      setStatus(""); // Reset status
      navigate("/dash/scores");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onTitleChanged = (e) => setTitle(e.target.value);
  const onComposerChanged = (e) => setComposer(e.target.value);
  const onUrlChanged = (e) => setUrl(e.target.value);
  const onLicenseChanged = (e) => setLicense(e.target.value);
  const onEditedChanged = (e) => setEdited(e.target.checked);
  const onStatusChanged = (e) => setStatus(e.target.value); // Handler for status change

  const canSave = [title, composer, license, status].every(Boolean) && !isLoading;

  const onSaveScoreClicked = async () => {
    if (canSave) {
      await updateScore({ id: score.id, title, composer, url, license, edited, status });
    }
  };

  const onDeleteScoreClicked = async () => {
    await deleteScore({ id: score.id });
    setOpen(false); // Close the dialog after deletion
    navigate("/dash/scores");
  };

  let content;

  if (isLoading || isComposersLoading) {
    content = (
      <section className="dash-container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  } else if (isError || isComposersError) {
    content = (
      <section className="dash-container">
        <p className="errmsg">{error?.data?.message || composersError?.data?.message}</p>
      </section>
    );
  } else if (isComposersSuccess) {
    const composerOptions = composers.ids.map((id) => (
      <MenuItem key={id} value={id}>
        {composers.entities[id].name}
      </MenuItem>
    ));

    content = (
      <section className="dash-container">
        <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
          <Typography variant="h3" align="center" mb={2}>
            Edit Score
          </Typography>

          <form>
            <Typography variant="h5">Score</Typography>
            <Typography variant="p">Title</Typography>
            <TextField
              fullWidth
              value={title}
              onChange={onTitleChanged}
            />

            <Typography variant="p">Composer</Typography>
            <FormControl fullWidth>
              <Select
                value={composer}
                onChange={onComposerChanged}
              >
                {composerOptions}
              </Select>
            </FormControl>

            <Typography variant="p">URL</Typography>
            <TextField
              fullWidth
              value={url}
              onChange={onUrlChanged}
            />

            <Typography variant="p">License</Typography>
            <TextField
              fullWidth
              value={license}
              onChange={onLicenseChanged}
            />

            <Typography variant="p">Edited</Typography>
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={edited}
                  onChange={onEditedChanged}
                  color="primary"
                />
              }
              label={edited ? "Yes" : "No"}
            />
            <br />

            {/* <Typography variant="p">Status</Typography>
            <FormControl fullWidth>
              <Select
                value={status}
                onChange={onStatusChanged}
              >
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
              </Select>
            </FormControl> */}
            <Grid container justifyContent="center" alignItems="center">
            <Button
              type="button"
              onClick={onSaveScoreClicked}
              disabled={!canSave}
              variant="contained"
              color="primary"
              style={{ marginTop: "1em" }}
            >
              Save Score
            </Button>
            <Button
              type="button"
              onClick={() => setOpen(true)}
              variant="contained"
              color="secondary"
              style={{ marginLeft: "10px", marginTop: "1em" }}
            >
              Delete Score
            </Button>
            </Grid>
          </form>
          {isError && <p className="errmsg">{error?.data?.message}</p>}
          {isDelError && <p className="errmsg">{delerror?.data?.message}</p>}
        </Paper>

        {/* dialog to confirm deletion of score */}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Score?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this score? This action will also delete the associated files for the score and is irreversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={onDeleteScoreClicked} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    );
  }

  return content;
};

export default EditScoreForm;