import { useState, useEffect } from "react"
import { useAddNewInstrumentMutation } from "./instrumentsApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import useTitle from "../../hooks/useTitle"

const INSTRUMENT_REGEX = /^[A-z]{3,20}$/
const TUNING_REGEX = /^[A-z]{1,2}$/

const NewInstrumentForm = () => {
    useTitle('New Instrument')

    const [addNewInstrument, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewInstrumentMutation()

    const navigate = useNavigate()

    const [instrumentname, setInstrumentname] = useState('')
    const [validInstrumentname, setValidInstrumentname] = useState(false)
    const [tuning, setTuning] = useState('')
    const [validInstrumentTuning, setValidInstrumentTuning] = useState(false)

    useEffect(() => {
        setValidInstrumentname(INSTRUMENT_REGEX.test(instrumentname))
    }, [instrumentname])

    useEffect(() => {
        setValidInstrumentTuning(TUNING_REGEX.test(tuning))
    }, [tuning])

    useEffect(() => {
        if (isSuccess) {
            setInstrumentname('')
            setTuning('')
            navigate('/dash/instruments')
        }
    }, [isSuccess, navigate])

    const onInstrumentnameChanged = e => setInstrumentname(e.target.value)
    const onInstrumentTuningChanged = e => setTuning(e.target.value)

    let canSave = [ validInstrumentname, validInstrumentTuning].every(Boolean) && !isLoading

    const onSaveInstrumentClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewInstrument({ name: instrumentname, tuning: tuning })
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"
    const validInstrumentClass = !validInstrumentname ? 'form__input--incomplete' : ''

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveInstrumentClicked}>
                <div className="form__title-row">
                    <h2>New Instrument</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="instrumentname">
                    Name: <span className="nowrap">[3-20 letters]</span></label>
                <input
                    className={`form__input ${validInstrumentClass}`}
                    id="instrumentname"
                    name="instrumentname"
                    type="text"
                    autoComplete="off"
                    value={instrumentname}
                    onChange={onInstrumentnameChanged}
                />

                <label className="form__label" htmlFor="instrumentname">
                    Tuning: <span className="nowrap">[1-2 letters]</span></label>
                <input
                    className={`form__input ${validInstrumentClass}`}
                    id="tuning"
                    name="tuning"
                    type="text"
                    autoComplete="off"
                    value={tuning}
                    onChange={onInstrumentTuningChanged}
                />

            </form>
        </>
    )

    return content
}
export default NewInstrumentForm