import { useState, useEffect } from "react";
import { useUpdateComposerMutation, useDeleteComposerMutation } from "./composersApiSlice";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";

const COMPOSER_REGEX = /^[A-z]{3,20}/;

const EditComposerForm = ({ composer }) => {
  const [updateComposer, { isLoading, isSuccess, isError, error }] = useUpdateComposerMutation();
  const [deleteComposer, { isSuccess: isDelSuccess, isError: isDelError, error: delerror }] = useDeleteComposerMutation();
  const navigate = useNavigate();

  const [composername, setComposername] = useState(composer.name);
  const [bio, setBio] = useState(composer.bio || '');
  const [validComposername, setValidComposername] = useState(false);

  useEffect(() => {
    setValidComposername(COMPOSER_REGEX.test(composername));
  }, [composername]);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setComposername("");
      setBio("");
      navigate("/dash/composers");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onComposernameChanged = (e) => setComposername(e.target.value);
  const onBioChanged = (e) => setBio(e.target.value);

  const onSaveComposerClicked = async () => {
    if (validComposername) {
      await updateComposer({ id: composer.id, name: composername, bio });
    }
  };

  const onDeleteComposerClicked = async () => {
    await deleteComposer({ id: composer.id });
  };

  const canSave = [validComposername].every(Boolean) && !isLoading;

  const errClass = (isError || isDelError) ? "errmsg" : "offscreen";
  const validComposerClass = !validComposername ? "form__input--incomplete" : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let content;

  if (isLoading) {
    content = (
      <section className="dash-container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  } else {
    content = (
      <section className="dash-container">
        <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
          <Typography variant="h3" align="center" mb={2}>
            Edit Composer
          </Typography>

          <form>
            <Typography variant="h5">Composer</Typography>
            <Typography variant="p">Name</Typography>
            <TextField
              fullWidth
              value={composername}
              onChange={onComposernameChanged}
              error={!validComposername}
              helperText={!validComposername ? "Name must be 3-20 letters" : ""}
            />
            <Typography variant="p">Bio</Typography>
            <TextField
              fullWidth
              value={bio}
              onChange={onBioChanged}
              multiline
              rows={4}
            />

            <Button
              type="button"
              onClick={onSaveComposerClicked}
              disabled={!canSave}
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Save Composer
            </Button>
            <Button
              type="button"
              onClick={onDeleteComposerClicked}
              variant="contained"
              color="secondary"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              Delete Composer
            </Button>
          </form>
          {isError && <p className="errmsg">{error?.data?.message}</p>}
          {isDelError && <p className="errmsg">{delerror?.data?.message}</p>}
        </Paper>
      </section>
    );
  }

  return content;
};

export default EditComposerForm