import { store } from "../../app/store";
import { scoresApiSlice } from "../scores/scoresApiSlice";
import { usersApiSlice } from "../users/usersApiSlice";
import { composersApiSlice } from "../composers/composersApiSlice";
import { instrumentsApiSlice } from "../instruments/instrumentsApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Prefetch = () => {
  useEffect(() => {
    //store.dispatch(scoresApiSlice.util.prefetch('getScores', 'scoresList', { force: true }))
    store.dispatch(
      usersApiSlice.util.prefetch("getUsers", "usersList", { force: true }),
      composersApiSlice.util.prefetch("getComposers", "composersList", {
        force: true,
      }),
      instrumentsApiSlice.util.prefetch("getInstruments", "instrumentsList", {
        force: true,
      }),
      scoresApiSlice.util.prefetch("getScores", "scoresList", { force: true })
    );
  }, []);

  return <Outlet />;
};
export default Prefetch;
