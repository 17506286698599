import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Copyright() {
  return (
    <Typography variant="body2" color="gray" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const PublicFooter = () => {
  return (
    <>
      {/* Footer */}
      <Box
        sx={{
          pb: 6,
          position: "relative",
          width: "100%",
          bottom: 0,
          textAlign: "center",
        }}
        component="footer"
      >
        <Typography
          variant="subtitle1"
          align="center"
          color="gray"
          component="p"
        >
          MultiScore Project
        </Typography>
        {/* <Copyright /> */}
      </Box>
      {/* End footer */}
    </>
  );
};

export default PublicFooter;
