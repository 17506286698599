import { useNavigate } from "react-router-dom";
import { useGetComposersQuery } from "./composersApiSlice";
import { memo } from "react";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import useAuth from "../../hooks/useAuth";

const Composer = ({ composerId }) => {
  const { composer } = useGetComposersQuery("composersList", {
    selectFromResult: ({ data }) => ({
      composer: data?.entities[composerId],
    }),
  });

  const { isAdmin, isEmployee } = useAuth();

  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/dash/composers/${composerId}`);
  };

  if (composer) {
    const handleEdit = (e) => {
      e.stopPropagation(); // Prevent the row click event
      navigate(`/dash/composers/edit/${composerId}`);
    };

    return (
      <TableRow onClick={handleRowClick} className="table-row">
        <TableCell>{composer.name}</TableCell>
        {(isAdmin || isEmployee) && (
          <TableCell>
            <IconButton color="primary" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  } else return null;
};

const memoizedComposer = memo(Composer);

export default memoizedComposer;