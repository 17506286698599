import React from 'react';
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import { Tabs,Tab,Typography,Button,Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';
import AudioFileRoundedIcon from '@mui/icons-material/AudioFileRounded';
import LaunchIcon from '@mui/icons-material/Launch';
import Container from "@mui/material/Container";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AddIcon from '@mui/icons-material/Add';

const Welcome = () => {
  const { username, isAdmin, isEmployee, isAssistant, isVisitor } = useAuth();

  useTitle(`MultiScore: ${username}`);

  const date = new Date();
  const today = new Intl.DateTimeFormat("en-US", {
    dateStyle: "full",
    timeStyle: "long",
  }).format(date);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const content = (
    <section className="dash-container">    
              <Box
          sx={{
            bgcolor: "inherit",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="md">
            <Grid padding="2rem" component={Paper}>
            <Typography
              component="h1"
              variant="h3"
              sx={{ fontWeight: 500 }}
              align="center"
              gutterBottom
            >
              Welcome to MUSCAT!
            </Typography>

            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
               <span style={{ fontWeight: 600 }}>M</span>ultimodal 
               M<span style={{ fontWeight: 600 }}>us</span>ic 
               <span style={{ fontWeight: 600 }}> C</span>ollection for  
               <span style={{ fontWeight: 600 }}> A</span>utomatic 
               <span style={{ fontWeight: 600 }}> T</span>ranscription
            </Typography>

            <Box m={2}> {/* Applying margin here */}
              <Divider />
            </Box>

            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              From this page you can access the corpus Scores, Audios and Prints. Contributing to MUSCAT increases the size and diversity of the collection.
            </Typography>            
            <Stack
              sx={{ pt: 4 }}
              direction="column"
              spacing={2}
              justifyContent="center"
              width={1}
            >
              <Button
                align="center"
                variant="contained"
                to="/dash/scores/new"
                component={Link}
                startIcon={<AddIcon />}
                style={{ color: 'white' }}
                fontWeight="bold"
              >
                Contribute
              </Button>
              <Button
                variant="contained"
                to="/dash/scores"
                component={Link}
                startIcon={<AudioFileRoundedIcon />}
                style={{ color: 'white' }}
              >
                Scores
              </Button> 
              {/* composers */}
              <Button
                variant="contained"
                to="/dash/composers"
                component={Link}
                startIcon= {<AccountCircleIcon />}
                style={{ color: 'white' }}
              >
                Composers
              </Button>
              {/* instruments */}
              <Button
                variant="contained"
                to="/dash/instruments"
                component={Link}
                startIcon= {<MusicNoteIcon />}
                style={{ color: 'white' }}
              >
                Instruments
              </Button>
            </Stack>
            </Grid>
          </Container>
        </Box>


    </section>
  );

  return content;
};
export default Welcome;
