import { useState, useEffect } from "react";
import { useAddNewComposerMutation } from "./composersApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import useTitle from "../../hooks/useTitle";

const COMPOSER_REGEX = /^[A-z]{3,20}$/;

const NewComposerForm = () => {
  useTitle("New Composer");

  const [addNewComposer, { isLoading, isSuccess, isError, error }] =
    useAddNewComposerMutation();

  const navigate = useNavigate();

  const [composername, setComposername] = useState("");
  const [validComposername, setValidComposername] = useState(false);

  useEffect(() => {
    setValidComposername(COMPOSER_REGEX.test(composername));
  }, [composername]);

  useEffect(() => {
    if (isSuccess) {
      setComposername("");
      navigate("/dash/composers");
    }
  }, [isSuccess, navigate]);

  const onComposernameChanged = (e) => setComposername(e.target.value);

  const canSave = [validComposername].every(Boolean) && !isLoading;

  const onSaveComposerClicked = async (e) => {
    e.preventDefault();
    console.log(composername);
    if (canSave) {
      await addNewComposer({ composername });
    }
  };

  const errClass = isError ? "errmsg" : "offscreen";
  const validComposerClass = !validComposername
    ? "form__input--incomplete"
    : "";

  const content = (
    <>
      <p className={errClass}>{error?.data?.message}</p>

      <form className="form" onSubmit={onSaveComposerClicked}>
        <div className="form__title-row">
          <h2>New Composer</h2>
          <div className="form__action-buttons">
            <button className="icon-button" title="Save" disabled={!canSave}>
              <FontAwesomeIcon icon={faSave} />
            </button>
          </div>
        </div>
        <label className="form__label" htmlFor="composername">
          Name: <span className="nowrap">[3-20 letters]</span>
        </label>
        <input
          className={`form__input ${validComposerClass}`}
          id="composername"
          name="composername"
          type="text"
          autoComplete="off"
          value={composername}
          onChange={onComposernameChanged}
        />
      </form>
    </>
  );

  return content;
};
export default NewComposerForm;
