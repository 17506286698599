import NewScoreForm from "./NewScoreForm";
import { useGetScoresQuery } from "../scores/scoresApiSlice";
import PulseLoader from "react-spinners/PulseLoader";
import useTitle from "../../hooks/useTitle";

const NewScore = () => {
  useTitle("New Score");

  const content = <NewScoreForm/>;

  return content;
};
export default NewScore;
