import { useState, useEffect } from "react";
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";
import { ROLES } from "../../config/roles";

const USER_REGEX = /^[A-z]{3,20}$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/;

const EditUserForm = ({ user }) => {
  const [updateUser, { isLoading, isSuccess, isError, error }] = useUpdateUserMutation();
  const [deleteUser, { isSuccess: isDelSuccess, isError: isDelError, error: delerror }] = useDeleteUserMutation();
  const navigate = useNavigate();

  const [username, setUsername] = useState(user.username);
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [roles, setRoles] = useState(user.roles);
  const [active, setActive] = useState(user.active);

  useEffect(() => {
    setValidUsername(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidConfirmPassword(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setUsername("");
      setPassword("");
      setConfirmPassword("");
      setRoles([]);
      navigate("/dash/users");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onUsernameChanged = (e) => setUsername(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);
  const onConfirmPasswordChanged = (e) => setConfirmPassword(e.target.value);

  const onRolesChanged = (e) => {
    const values = Array.from(e.target.selectedOptions, (option) => option.value);
    setRoles(values);
  };

  const onActiveChanged = () => setActive((prev) => !prev);

  const onSaveUserClicked = async () => {
    if (password && validPassword && validConfirmPassword) {
      await updateUser({ id: user.id, username, password, roles, active });
    } else if (!password) {
      await updateUser({ id: user.id, username, roles, active });
    }
  };

  const onDeleteUserClicked = async () => {
    await deleteUser({ id: user.id });
  };

  const canSave = password
    ? [roles.length, validUsername, validPassword, validConfirmPassword].every(Boolean) && !isLoading
    : [roles.length, validUsername].every(Boolean) && !isLoading;

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validUserClass = !validUsername ? "form__input--incomplete" : "";
  const validPwdClass = password && !validPassword ? "form__input--incomplete" : "";
  const validConfirmPwdClass = password && !validConfirmPassword ? "form__input--incomplete" : "";
  const validRolesClass = !Boolean(roles.length) ? "form__input--incomplete" : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let content;

  if (isLoading) {
    content = (
      <section className="dash-container">
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  } else {
    content = (
      <section className="dash-container">
        <Paper style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}>
          <Typography variant="h3" align="center" mb={2}>
            Edit User
          </Typography>

          <form>
            <TextField
              fullWidth
              label="Username"
              value={username}
              onChange={onUsernameChanged}
              error={!validUsername}
              helperText={!validUsername ? "Username must be 3-20 letters" : ""}
              margin="normal"
            />

            <TextField
              fullWidth
              label="New Password"
              type="password"
              value={password}
              onChange={onPasswordChanged}
              error={password && !validPassword}
              helperText={password && !validPassword ? "Password must be 4-12 chars incl. !@#$%" : ""}
              margin="normal"
            />

            <TextField
              fullWidth
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={onConfirmPasswordChanged}
              error={password && !validConfirmPassword}
              helperText={password && !validConfirmPassword ? "Passwords must match" : ""}
              margin="normal"
            />

            <FormControlLabel
              control={<Checkbox checked={active} onChange={onActiveChanged} />}
              label="Active"
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="roles-label" style={{ backgroundColor: 'white' }} >Assigned Roles</InputLabel>
              <Select
                labelId="roles-label"
                id="roles"
                multiple
                value={roles}
                onChange={onRolesChanged}
                renderValue={(selected) => selected.join(", ")}
                error={!Boolean(roles.length)}
              >
                {Object.values(ROLES).map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              type="button"
              onClick={onSaveUserClicked}
              disabled={!canSave}
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Save User
            </Button>
            <Button
              type="button"
              onClick={onDeleteUserClicked}
              variant="contained"
              color="secondary"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              Delete User
            </Button>
          </form>
          {isError && <p className="errmsg">{error?.data?.message}</p>}
          {isDelError && <p className="errmsg">{delerror?.data?.message}</p>}
        </Paper>
      </section>
    );
  }

  return content;
};

export default EditUserForm;