import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const scoresAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = scoresAdapter.getInitialState();

export const scoresApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScores: builder.query({
      query: () => ({
        url: "/scores",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedScores = responseData
        .map((score) => {
          score.id = score._id;
          return score;
        });
        return scoresAdapter.setAll(initialState, loadedScores);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Score", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Score", id })),
          ];
        } else return [{ type: "Score", id: "LIST" }];
      },
    }),
    getAllApprovedScores: builder.query({
      query: () => ({
        url: "/scores/approved",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedScores = responseData
        .map((score) => {
          score.id = score._id;
          return score;
        });
        return scoresAdapter.setAll(initialState, loadedScores);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Score", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Score", id })),
          ];
        } else return [{ type: "Score", id: "LIST" }];
      },
    }),
    getAllPendingScores: builder.query({
      query: () => ({
        url: "/scores/pending",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedScores = responseData
        .map((score) => {
          score.id = score._id;
          return score;
        });
        return scoresAdapter.setAll(initialState, loadedScores);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Score", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Score", id })),
          ];
        } else return [{ type: "Score", id: "LIST" }];
      },
    }),
    getScore: builder.query({
      query: (id) => ({
        url: `/scores/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const score = responseData;
        score.id = score._id;
        return score;
      },
      providesTags: (result, error, arg) => [{ type: "Score", id: arg }],
    }),
    approveScore: builder.mutation({
      query: (id) => ({
        url: `/scores/approve/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Score", id: arg }],
    }),
    rejectScore: builder.mutation({
      query: (id) => ({
        url: `/scores/reject/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Score", id: arg }],
    }), 
    getScoresCountByComposer: builder.query({
      query: () => ({
        url: '/scores/scores-count-by-composer',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        // Assuming responseData is an array of { _id: string, count: number, composer: string }
        return responseData.map(item => ({
          id: item._id,
          count: item.count,
          composer: item.composer,
        }));
      },
      providesTags: (result, error, arg) => {
        if (result) {
          return [
            { type: "ScoreCountByComposer", id: "LIST" },
            ...result.map(({ id }) => ({ type: "ScoreCountByComposer", id })),
          ];
        } else return [{ type: "ScoreCountByComposer", id: "LIST" }];
      },
    }),
    addNewScore: builder.mutation({
      query: (initialScore) => ({
        url: "/scores",
        method: "POST",
        body: {
          ...initialScore,
        },
      }),
      invalidatesTags: [{ type: "Score", id: "LIST" }],
    }),
    updateScore: builder.mutation({
      query: (score) => ({
        url: `/scores/${score.id}`,
        method: "PATCH",
        body: {
          ...score,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Score", id: arg.id }],
    }),
    deleteScore: builder.mutation({
      query: (id) => ({
        url: `/scores/${id}`,
        method: "DELETE",
      }),
    }),
    getScoresFiles: builder.query({
      query: (id) => ({
        url: `/scores/files/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getPrintFiles: builder.query({
      query (id) {
        return {
          url: `/prints/files/${id}`,
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),
    getAudioFiles: builder.query({
      query (id) {
        return {
          url: `/audios/files/${id}`,
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),
    getCutsData: builder.query({
      query (id) {
        return {
          url: `/scores/cuts/${id}`,
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),
    getScoreComposer: builder.query({
      query: (id) => ({
        url: `/scores/composer/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const composer = responseData;
        composer.id = composer._id;
        return composer;
      },
      providesTags: (result, error, arg) => [{ type: "Composer", id: arg }],
    }),
  }),
});

export const {
  useGetScoresQuery,
  useGetAllApprovedScoresQuery,
  useGetAllPendingScoresQuery,
  useGetScoreQuery,
  useApproveScoreMutation,
  useRejectScoreMutation,
  useGetScoresCountByComposerQuery,
  useAddNewScoreMutation,
  useUpdateScoreMutation,
  useDeleteScoreMutation,
  useGetScoresFilesQuery,
  useGetPrintFilesQuery,
  useGetAudioFilesQuery,
  useGetCutsDataQuery,
  useGetScoreComposerQuery,
} = scoresApiSlice;

// Returns the query result object
export const selectScoresResult = scoresApiSlice.endpoints.getScores.select();

// Creates memoized selector
const selectScoresData = createSelector(
  selectScoresResult,
  (scoresResult) => scoresResult.data // normalized state object with ids & entities
);

// Get selectors
export const {
  selectAll: selectAllScores,
  selectById: selectScoreById,
  selectIds: selectScoreIds,
} = scoresAdapter.getSelectors((state) => selectScoresData(state) ?? initialState);