import React, { useEffect, useState } from "react";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { useGetScoresCountByComposerQuery } from "../../features/scores/scoresApiSlice";
import { Paper, Typography } from "@mui/material";

// Register the required components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Title
);

const Analytics = () => {
  useTitle("MultiScore: Analytics");

  const {
    data: scoresCountData,
    isLoading,
    isError,
  } = useGetScoresCountByComposerQuery();

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (scoresCountData) {
      const labels = scoresCountData.map((item) => item.composer);
      const counts = scoresCountData.map((item) => item.count);

      setChartData({
        labels,
        datasets: [
          {
            label: "# of Scores",
            data: counts,
            backgroundColor: "#82a6a1",
            borderColor: "#82a6a1",
            borderWidth: 1,
          },
        ],
      });
    }
  }, [scoresCountData]);

  if (isLoading) return <PulseLoader />;
  if (isError) return <div>Error loading data</div>;

  return (
    <section className="dash-container">
      <Paper
        style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}
      >
        <Typography variant="h3" align="center" mb={2}>
          Analytics
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          Scores Count by Composer
        </Typography>
        {chartData && (
          <div className="chart-container">
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        )}
      </Paper>
    </section>
  );
};

export default Analytics;
