import { Typography } from "@mui/material";
import useAuth from "../hooks/useAuth";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

function Copyright() {
  return (
    <Typography variant="body2" color="gray" align="center">
      {"Copyright © "}{new Date().getFullYear()}
    </Typography>
  );
}

const DashFooter = () => {
  const { username, roles } = useAuth();

  const content = (
    <AppBar position="static" style={{ bottom: 0 }}>
      <Toolbar
        style={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'relative'}}
      >
        {/* <CameraIcon sx={{ mr: 2 }} /> */}
        {/* <Typography style={{ textAlign:"left" }}>
          Current User: {username}
        </Typography> */}

        <Typography style={{ textAlign:"center", position:"absolute", left: "0" ,right: "0",margin: "auto" }}>
          MultiScore
          {/* <Copyright /> */}
        </Typography>

        <Typography style={{ textAlign:"right" }}>
          {roles.length === 1 ? "Role: " : "Roles: "}
          {roles.join(", ")}
        </Typography>
      </Toolbar>
    </AppBar>
  );
  return content;
};
export default DashFooter;
