import React, { useState, useEffect } from "react";
import { useGetAllPendingScoresQuery } from "./scoresApiSlice";
import Score from "./Score";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useAuth from "../../hooks/useAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ScoresSubmissionsList = () => {
  const { username, isAdmin, isEmployee, isAssistant, isVisitor } = useAuth();
  useTitle("MultiScore: Submissions List");
  const theme = useTheme();
  const {
    data: scores,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllPendingScoresQuery("scoresList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  useEffect(() => {
    if (isSuccess) {
      const results = scores.ids;
      setTotalPages(Math.ceil(results.length / 15));
    }
  }, [isSuccess, scores]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  let content;

    // kick out if not admin
    if (!isAdmin) {
      return (
        <section className="dash-container">
          <Paper
            style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}
          >
            <Typography variant="h3" align="center">
              You are not authorized to view this page.
            </Typography>
          </Paper>
        </section>
      )
    } 

  if (isLoading) {
    content = (
      <section className="dash-container">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseLoader color={"#82a6a1"} />
        </Grid>
      </section>
    );
  }

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }
  // // if the scores list is empty, display a message
  if (isSuccess && scores.ids.length === 0) {
    content = (
      <section className="dash-container">
        <Paper
          style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}
        >
          <Typography variant="h3" align="center">
            MUSCAT Submissions
          </Typography>
          <Typography variant="h5" align="center" mt={8}>
            No pending submissions at this time!
          </Typography>
        </Paper>
      </section>
    );
  }


  if (isSuccess && scores.ids.length > 0) {
    const results = scores.ids;
    const startIndex = (currentPage - 1) * 15;
    const currentResults = results.slice(startIndex, startIndex + 15);
    const totalItems = results.length;
    const startItem = startIndex + 1;
    const endItem = Math.min(startIndex + 15, totalItems);
    const tableContent = currentResults.map((scoreId) => (
      <Score key={scoreId} scoreId={scoreId} redirectToSubmissions={true}/>
    ));

    content = (
      <>
        <section className="dash-container">
          <Paper
            style={{ padding: "20px", margin: "20px auto", maxWidth: "800px" }}
          >
            <Typography variant="h3" align="center">
              MUSCAT Submissions
            </Typography>
            {/* Pagination controls at the top */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{ marginTop: "1em", marginBottom: "1em" }}
            >
              <Grid item>
                <Button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ArrowBackIcon />
                </Button>
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 600 }}>
                  Page {currentPage} of {totalPages}{" "}
                </span>
                <span>
                  {" "}
                  ({startItem} - {endItem} of {totalItems})
                </span>
              </Grid>
              <Grid item>
                <Button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ArrowForwardIcon />
                </Button>
              </Grid>
            </Grid>
            {/* Table */}
            <TableContainer style={{ width: "auto" }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                      }}
                    >
                      Piece
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                      }}
                    >
                      Composer
                    </TableCell>
                    {isAdmin && (
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        Edit
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>{tableContent}</TableBody>
              </Table>
            </TableContainer>
            {/* Pagination controls at the bottom */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{ marginTop: "1em", marginBottom: "1em" }}
            >
              <Grid item>
                <Button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ArrowBackIcon />
                </Button>
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 600 }}>
                  Page {currentPage} of {totalPages}{" "}
                </span>
                <span>
                  {" "}
                  ({startItem} - {endItem} of {totalItems})
                </span>
              </Grid>
              <Grid item>
                <Button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ArrowForwardIcon />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </section>
      </>
    );
  }

  return content;
};

export default ScoresSubmissionsList;