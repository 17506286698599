import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "./usersApiSlice";
import { memo } from "react";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import useAuth from "../../hooks/useAuth";

const User = ({ userId }) => {
  const { user } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[userId],
    }),
  });

  const navigate = useNavigate();

  const { isAdmin } = useAuth();

  if (user) {
    const handleEdit = (e) => {
      e.stopPropagation(); // Prevent the row click event
      navigate(`/dash/users/edit/${userId}`);
    };

    const userRolesString = user.roles.toString().replaceAll(",", ", ");

    return (
      <TableRow>
        <TableCell>{user.username}</TableCell>
        <TableCell>{userRolesString}</TableCell>
        <TableCell>
          {isAdmin && (
            <IconButton color="primary" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  } else return null;
};

const memoizedUser = memo(User);

export default memoizedUser;